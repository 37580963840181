import React, { Component } from 'react';

import {apiQuizTypeUrl, DEFAULT_LANGUAGE} from '../../../../../../config';
import axios from "../../../../../../server/interceptors";

import Form from 'react-bootstrap/Form';
import { FormattedMessage } from 'react-intl';
import SessionManager from "../../../../../../utils/authHandling/session-manager";

class QuizTypeRadioSelection extends Component{

  constructor(props){
    super(props);
    this.state={
      quizTypesFromRequest: [],
      selectedQuizType: ""
    }
    this.getQuizTypeRequest = this.getQuizTypeRequest.bind(this);
    this.selectQuizType = this.selectQuizType.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getQuizTypeRequest(){
    return new Promise((resolve, reject) => {
      axios.get(apiQuizTypeUrl, {
        headers: {
          'Content-Type': 'application/json',          
        }}).then(function (response){
        if(response.status === 200 || response.status === 201){
          resolve(response.data);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  selectQuizType(value){
    this.props.changeQuizType(value);
  }

  onChange(){
    this.setState({
      selectQuizType: this.props.currentSelection
    });
  }

  componentDidMount(){
    this.getQuizTypeRequest().then(result => {
      const quizTypes = result.QuizTypes.map(item => ({Name: item.Name, Id: item.Id}));
      this.setState({
        quizTypesFromRequest: quizTypes
      });
    }, function(error){
      console.log(error.response.data.Messages);
    });
  }

  render(){
    return(
      <div>
        <br/>
        <h5><FormattedMessage id="qp-quiz-type"/></h5>
        <Form className="checklist">
          <Form.Group>
            {this.state.quizTypesFromRequest.map((item, index) => {
              return( 
                <Form.Check
                  inline
                  key={index}
                  label={item.Name}
                  name="radioButton"
                  type="radio"
                  onClick={() => this.selectQuizType(item.Id)}
                  checked={this.props.currentSelection === item.Id}
                  onChange={this.onChange}
                />
              )
            })}
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default QuizTypeRadioSelection;