import React, { Component } from 'react';
import NumericInput from 'react-numeric-input';

class SingleNumberInput extends Component{

  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(newValue){
    this.props.onChange(newValue, this.props.index, this.props.indexC);
  }


  render(){
    return(
      <div>
        <NumericInput onChange={this.onChange} mobile value={this.props.value}/>
      </div>
    );
  }
}

export default SingleNumberInput;