import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import {FaTrash} from 'react-icons/fa'

/** Edited by POR 2020.12.04 **/
class DynamicLessonAdditionalMaterialTable extends Component{

  constructor(props){
    super(props);
    this.deleteAdditionalMaterial = this.deleteAdditionalMaterial.bind(this);
  }

  deleteAdditionalMaterial(e){
    this.props.updateParent(e)
  }

  render(){
    const { data } = this.props;
    return(
      <div>
        <Table>
          <thead>
            <tr>
              <th><FormattedMessage id="am-id"/></th>
              <th><FormattedMessage id="am-just-title"/></th>
              <th><FormattedMessage id="am-description"/></th>
              <th><FormattedMessage id="am-url"/></th>
              <th><FormattedMessage id="am-pages"/></th>
              <th><FormattedMessage id="am-delete"/></th>
            </tr>
          </thead>
          <tbody>
            {
              data.length === 0 ? <tr>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
              </tr> :
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td id={index + "ID"}>{item.Id}</td>
                    <td id={index + "TITLE"}>{item.AdditionalMaterialModel.Title}</td>
                    <td id={index + "DESCRIPTION"}>{item.AdditionalMaterialModel.Description !== "" && item.AdditionalMaterialModel.Description.length > 20 ? item.AdditionalMaterialModel.Description.substring(0, 20) + "..." : item.AdditionalMaterialModel.Description }</td>
                    <td id={index + "URL"}><a href={item.AdditionalMaterialModel.Url} target="_blank"><FormattedMessage id="am-link"/></a></td>
                    <td id={index + "PAGES"}>{item.Page}</td>
                    <td id={index + "DELETE"}><div className="btn btn-danger">
                      <FaTrash onClick={() => this.deleteAdditionalMaterial(item.Id)}/>
                    </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

export default DynamicLessonAdditionalMaterialTable;