import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./view-code-page.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FaSearch, FaCodeBranch } from "react-icons/fa";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import { onGradeCall } from "../../server/getLessonGradesCall/lesson-grade-call";
import { onSubjectCall } from "../../server/getLessonSubjectCall/lesson-subject-call";
import Table from "react-bootstrap/Table";
import SpinnerLoader from "../../components/spinnerLoader/spinner-loader";
import { Link } from "react-router-dom";
import {
  getCodesTypes,
  getCodesByQuery,
} from "../../server/getCodeTypesCall/code-type-call";
import Pagination from "@material-ui/lab/Pagination";

const ViewCodePage = () => {
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState([]);
  const [gradeSelected, setGradeSelected] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState([]);
  const [codeTypes, setCodeTypes] = useState([]);
  const [codeTypeSelected, setCodeTypeSelected] = useState(null);
  const [codeLike, setCodeLike] = useState("");
  const [codes, setCodes] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);

  const intl = useIntl();

  useEffect(() => {
    getGrades();
    getSubjects();
    getCodeTypes();
    getCodes(
      gradeSelected,
      subjectSelected,
      codeTypeSelected ? codeTypeSelected.value : undefined,
      codeLike,
      page,
      pageSize
    );
  }, []);

  const handleSearch = () => {
    const page = 1;
    setPage(page);
    getCodes(
      gradeSelected,
      subjectSelected,
      codeTypeSelected ? codeTypeSelected.value : undefined,
      codeLike,
      page,
      pageSize
    );
  };

  const getCodes = async (levels, subjects, type, codeLike, page, pageSize) => {
    page = page - 1;
    setLoading(true);
    try {
      const data = {
        TypeId: type && Number(type),
        CodeLevelModels: levels.map((item) => ({
          LevelCode: item.value,
        })),
        CodeSubjectModels: subjects.map((item) => ({
          SubjectCode: item.value,
        })),
        Description: codeLike,
      };
      const codes = await getCodesByQuery(data, page, pageSize);
      setCodes(codes.Items);
      setCount(codes.TotalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getGrades = async () => {
    try {
      onGradeCall(undefined).then((data) => {
        const grades = data.map((item) => ({
          value: item.code,
          label: item.title,
        }));
        setGrades(grades);
      });
    } catch (error) {
      alert(error);
    }
  };

  const getSubjects = async () => {
    try {
      const data = await onSubjectCall();
      const subjects = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      setSubjects(subjects);
    } catch (error) {
      alert(error);
    }
  };

  const getCodeTypes = async () => {
    try {
      const data = await getCodesTypes();
      const codeTypes = data.map((item) => ({
        value: item.Id,
        label: item.Name,
      }));
      setCodeTypes(codeTypes);
    } catch (error) {
      alert(error);
    }
  };

  const handleChangePaginate = (event, value) => {
    setPage(value);
    getCodes(
      gradeSelected,
      subjectSelected,
      codeTypeSelected ? codeTypeSelected.value : undefined,
      codeLike,
      value,
      pageSize
    );
  };

  return (
    <Container fluid className="card-alt">
      <div className="wrapper-view-unit">
        <h3 className="mb-3">
          <FaCodeBranch />
          <FormattedMessage id="nav-codes-edit" />
        </h3>
        <div
          className="wrapper-selects col-four-select"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="wrapper-content-select">
            <MultiSelect
              bsSize="small"
              value={gradeSelected}
              onChange={(selected) => setGradeSelected(selected)}
              options={grades}
              placeholder={intl.formatMessage({ id: "codes-level" })}
              closeOnChangedValue={false}
            />
          </div>
          <div className="wrapper-content-select">
            <MultiSelect
              bsSize="small"
              value={subjectSelected}
              onChange={(selected) => setSubjectSelected(selected)}
              options={subjects}
              placeholder={intl.formatMessage({ id: "codes-subject" })}
            />
          </div>
          <div className="wrapper-content-select">
            <Select
              isClearable={true}
              bsSize="small"
              value={codeTypeSelected}
              onChange={(selected) => setCodeTypeSelected(selected)}
              options={codeTypes}
              placeholder={intl.formatMessage({ id: "codes-select-element" })}
            />
          </div>
          <div className="wrapper-content-select">
            <Form.Control
              style={{ marginTop: "0px" }}
              bsSize="small"
              type="text"
              onChange={(e) => setCodeLike(e.target.value)}
              name="code"
              value={codeLike}
              placeholder={intl.formatMessage({
                id: "codes-search-by-id-code-or-description",
              })}
            />
          </div>
          <div className="content-button-search fixed-btn">
            <Button variant="primary" type="button" onClick={handleSearch}>
              {" "}
              <FaSearch />
              <FormattedMessage id="unit-button-search" />
            </Button>
          </div>
        </div>
        {loading ? (
          <div style={{ marginTop: "40px" }}>
            <SpinnerLoader />
          </div>
        ) : (
          <div>
            {codes ? (
              <div>
                <div className="wrapper-table fixing-table-code">
                  <Table striped bordered hover className="w-100">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>
                          <FormattedMessage id="code-table-colum-code" />
                        </th>
                        <th>
                          <FormattedMessage id="code-table-colum-type" />
                        </th>
                        <th>
                          <FormattedMessage id="code-table-colum-desc" />
                        </th>
                        <th>
                          <FormattedMessage id="code-table-colum-level" />
                        </th>
                        <th>
                          <FormattedMessage id="code-table-colum-subject" />
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {codes.map((item) => (
                        <tr key={item.Id}>
                          <td>{item.Id}</td>
                          <td>{item.Code}</td>
                          <td>{item.CodeTypeName}</td>
                          <td>{item.Description}</td>
                          <td>
                            <div className="td-wrapper">
                              {item.CodeLevelModels.map((item) => (
                                <div>{item.LevelName}</div>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="td-wrapper">
                              {item.CodeSubjectModels.map((item) => (
                                <div>{item.SubjectName}</div>
                              ))}
                            </div>
                          </td>
                          <td>
                            <Link to={`/create-code?id=${item.Id}`}>
                              <FormattedMessage id="unit-table-colum-opt-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="pageControls">
                  {count > 1 && (
                    <Pagination
                      count={count}
                      page={page}
                      onChange={handleChangePaginate}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default ViewCodePage;
