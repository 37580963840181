import React, { Component } from 'react';

import SimpleFieldGenerator from '../../../simpleFieldGenerator';
import { FormattedMessage } from 'react-intl';

import './create-lesson-objectives.css';

class CreateLessonObjectives extends Component{
  constructor(props){
    super(props);
    this.setObjectiveData = this.setObjectiveData.bind(this);
  }

  setObjectiveData(content){
    this.props.parentStateHandle("lessonObjectives", content);
  }

  render(){
    return(
      <div className="card-alt-2">
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-richtext"/></p> : ""}
        <p><FormattedMessage id="cl-field-instruction"/></p>
        <SimpleFieldGenerator 
          titleId="af-objectives" 
          setParentState={this.setObjectiveData} 
          state={this.props.state}
        />
      </div>
    );
  } 
}

export default CreateLessonObjectives;