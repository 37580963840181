import axios from "../interceptors";
import { apiAdressUrl } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const onAddressCall = async function () {
  try {
    const response = await axios.get(apiAdressUrl, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    if (response.status === 200 || response.status === 201) {
      return response.data.AddressAt.map((item) => ({
        title: item.AddressName,
        code: item.Id,
      }));
    } else if (response.status === 401) {
      //session is not valid, user is sent to login
      alert("Session is not valid.");
      logoutUser();
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
