import React, { Component } from 'react';

import Dropzone from 'react-dropzone-uploader';
import { FormattedMessage } from 'react-intl';
import {apiAdditionalMaterialFileUploadUrl, DEFAULT_LANGUAGE} from '../../config';
import SessionManager from "../../utils/authHandling/session-manager";
//bootstrap
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';


class AdditionalInfoCoverUpload extends Component{
  constructor(props){
    super(props);
    this.state={
      imgSrc: "",
      uploadType: "image",
      reuploadDisplay: false,
      askForReplace: false,
      audioHeaders: []
    };
  }

  getUploadParams =  (headers) => {
    this.setState({dropzoneResponseObject: headers});
    let newState = this.state.audioHeaders;
    newState.push(headers);
    this.setState({audioHeaders: newState});
    return {
      url : apiAdditionalMaterialFileUploadUrl,
      headers: {
        'Token': SessionManager.getSession(),
        'Accept-Language': localStorage.getItem('i18nextLng') == null ? DEFAULT_LANGUAGE : localStorage.getItem('i18nextLng')
      }}
  };

  handleSubmit = (files, allFiles) => {
    let response = JSON.parse(this.state.dropzoneResponseObject.xhr.responseText);
    this.props.onChange("additionalMaterialCoverURLSrc", response.Messages[0]);
    this.setState({reuploadDisplay: false});
    allFiles.forEach(f => f.remove())
  };

  render(){
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
    return(
      <div>
        <hr/>
        <b><FormattedMessage id="cl-url-cover-upload"/></b>
        {this.props.src ?
          <div>
            <img
              // substitute for this.props.src
              src={this.props.src}
            />
            <Button
              style={{marginTop: "10px"}}
              onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay, askForReplace: true})}}
              aria-controls="dropzone-area"
              aria-expanded={this.state.reuploadDisplay}
            >
                <FormattedMessage id="cl-url-upload-media"/>
            </Button>
            <Collapse in={this.state.reuploadDisplay}>
              <div id="dropzone-area">
                {this.state.askForReplace ?
                  <div style={{textAlign: "center"}}>
                    <p><FormattedMessage id="cl-rewrite-warning"/></p>
                    <Button
                      onClick={() => {this.setState({askForReplace: false})}}
                      style={{margin: "5px"}}><FormattedMessage id="cl-yes"/></Button>
                    <Button
                      onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay})}}
                      style={{margin: "5px"}}
                      aria-controls="dropzone-area"
                      aria-expanded={this.state.reuploadDisplay}><FormattedMessage id="cl-no"/></Button>
                  </div> :
                  <Dropzone
                    accept="image/png,image/jpg"
                    key="Dropzone View"
                    getUploadParams={this.getUploadParams}
                    // onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleSubmit}
                    styles={{ dropzone: baseStyle }}
                    inputContent={"image/png,image/jpg"}
                  />
                }
              </div>
            </Collapse>
          </div> :
          <div>
            <Dropzone
              accept="image/png,image/jpg"
              key="Dropzone View"
              getUploadParams={this.getUploadParams}
              // onChangeStatus={this.handleChangeStatus}
              onSubmit={this.handleSubmit}
              styles={{ dropzone: baseStyle }}
              inputContent={"image/png,image/jpg"}
            />
          </div>}
        <div>

        </div>
        <hr/>
      </div>
    );
  }
}

export default AdditionalInfoCoverUpload;