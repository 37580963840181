import React, { Component } from 'react';
import LabelAndEditor from '../labelAndEditor';
import SimpleCharCount from '../simpleCharCount';

class TemplateInstructions extends Component{
  constructor(props){
    super(props);
    this.onChangeContent = this.onChangeContent.bind(this);
  }

  onChangeContent(lang, value){
    this.props.changeParentState(value, lang);
  }
  render(){
    return(
      <div>
        <LabelAndEditor
          label="ct-instruction-spanish"
          stateName="es"
          content={this.props.esContent}
          changeParentState={this.onChangeContent}
          require={this.props.require}
        />
        <SimpleCharCount
          content={this.props.esContent}
          maxChars={100}
        />
        <LabelAndEditor
          label="ct-instruction-english"
          stateName="en"
          content={this.props.enContent}
          changeParentState={this.onChangeContent}
          require={this.props.require}
        />
        <SimpleCharCount
          content={this.props.enContent}
          maxChars={100}
        />
      </div>
    );
  }
}

export default TemplateInstructions;