import React, { Component } from 'react';

import Table from 'react-bootstrap/Table';
import { FormattedMessage } from 'react-intl';
import Spinner from 'react-bootstrap/Spinner';
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';

class DynamicTable extends Component {
  constructor(props){
    super(props);
    this.state={
      nextButtonStatus: false,
      previousButtonStatus: true,
      ascending: true,
      showSelect: typeof props.showSelect !== 'undefined' ? props.showSelect : false,
    };

    this.changeOrder = this.changeOrder.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  changeOrder = () => {
    this.setState({
      ascending: !this.state.ascending
    }, function() {
      this.dataGeter();
    })
  };

  changePage = (direction) => {
    this.props.changePage(direction)
  };

  saveSelectId(id){
    sessionStorage.setItem("lastLevelId", id);
  }

  selectId(id){
    this.props.updateParent(id);
  }

  render() {
    return (
      <div>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th><FormattedMessage id="lev-id"/></th>
              <th><FormattedMessage id="lev-name"/></th>
              <th><FormattedMessage id="lev-code"/></th>
              <th></th>
              {this.state.showSelect ? <th/> : null}
            </tr>
          </thead>
          <tbody>
            {
              this.props.data.length === 0 ? <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}><Spinner animation="grow"/></div> :
              this.props.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td key={(index + "ID").toString}>{item.id}</td>
                  <td key={(index) + "NAME"}>{item.name}</td>
                  <td key={(index) + "CODE"}>{item.code}</td>
                  <td key={(index) + "generar"}>
                    {this.state.showSelect ?
                        <a href={item.url} target="_blank"><FormattedMessage id="lev-view"/></a> :
                    <Link
                        onClick={() => this.saveSelectId(item.id)}
                        to={{pathname: item.linkItems[0].link, id: item.id}}>
                        {item.linkItems[0].linkText ? item.linkItems[0].linkText : <FormattedMessage id="lev-view"/>}
                    </Link>
                    }
                  </td>
                  {this.state.showSelect ?
                      <td key={(index) + "select"}>
                        <Button variant="success"  onClick={() => this.selectId(item.id)}>
                          <FormattedMessage id="tb-attach"/>
                        </Button>
                      </td> : null}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
export default DynamicTable;