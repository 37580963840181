import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from "../../server/interceptors";
import { apiSubjectUrl, DEFAULT_LANGUAGE} from '../../config';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DynamicTable from '../../components/dynamicTableSubjects';
import {FaFlask, FaFile} from 'react-icons/fa'
import './view-subjects-page.css';
import SpinnerLoader from '../../components/spinnerLoader/spinner-loader';
import SessionManager from "../../utils/authHandling/session-manager";

class ViewSubjectsPage extends Component{

    constructor(props){
        super(props);
        this.state={
            loading: false,
            tableData: [{}],
            tablePage: 1,
            totalItems: 0,
            selectedRow: "",
        };
        this.onChange = this.onChange.bind(this);
        this.onDataCall = this.onDataCall.bind(this);
        this.selectRow = this.selectRow.bind(this);
    }

    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        this.setState({[field]: value});
    }


    onDataCall(e){
        this.setState({loading: true}, ()=>{

            axios.get(apiSubjectUrl, {
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{
                 if(typeof response.data !== 'undefined' && response.data !== null)
                 {
                        const dataReceived = response.data.map((item) => ({ "id": item.Id,
                            "name": item.Name,
                            "code": item.Code,
                            "linkItems": [
                                {
                                    "subjectsId": item.Id,
                                    "link": "./edit-subject"
                                }
                            ]}));
                        this.setState({
                            tableData: dataReceived,
                            tablePage: 1,
                            totalItems: dataReceived.length,
                            loading: false
                        });
                    }
                 else {
                     this.setState({
                         tableData: [],
                         tablePage: 1,
                         totalItems: 0,
                         loading: false
                     })
                 }
            }).catch(error => {
                alert(error);
            });//Refreshing button statuses
        });
    }

    componentDidMount(){
       this.onDataCall();
    }

    selectRow(e){
        // send the selected id.
        this.props.updateParent(e, this.state.selectedRow);
    }

    render(){

        return(
            <Container fluid className="card-alt">
                <Row>
                    <Col xs={12}>
                        <h3>
                            <FaFile/><FormattedMessage id="sub-subjects"/>{' '}
                        </h3>
                    </Col>
                </Row>

                {/* If there are entries for the table, display it */}
                {this.state.loading ? <div style={{marginTop: "40px"}}><SpinnerLoader/></div>
                    :
                    <div>
                        {this.state.totalItems > 0 &&
                        <div>
                            <hr/>
                            <div className="tableControls">
                                <p style={{textAlign: 'right'}}>
                                    <FormattedMessage id="vl-results"/> :  {this.state.totalItems}
                                </p>
                            </div>
                            <DynamicTable
                                data={this.state.tableData}
                                itemsPerPage={10}
                                language={this.props.language}
                                pageNumber={this.state.tablePage}
                                changePage={(direction) => {this.onChangePage(direction)}}
                                resultQuantity={this.state.totalItems}
                                showSelect={this.state.showSelect}
                                updateParent={this.selectRow}
                            />
                        </div>
                        }
                    </div>
                }
            </Container>
        );
    }
}

export default ViewSubjectsPage;