import React, { Component } from 'react';

import ValidatedTextInput from '../../../validatedTextField';
import RadioButtonSubjects from '../../../radioButtonSubjects';
import RadioButtonGrades from '../../../radioButtonGrades';
import {FormattedMessage} from "react-intl";
import {Col, Row, Card} from "react-bootstrap";
import AdditionalInfoCoverUpload from "../../../additionalInfoCoverUpload";
import AdditionalMaterialUpload from "../../../additionalMaterialUpload";
import ForDownloadCheck from "../../../forDownloadCheck";

class CreateAdditionalMaterialInfoSection extends Component{
  
  constructor(props){
    super(props);
    this.state = {
        additionalMaterialDataState: this.props.state,
        additionalMaterialTitle: "",
        additionalMaterialDescription: "",
        additionalMaterialSubject: "",
        additionalMaterialGrade: "",
        additionalMaterialURL: "",
        additionalMaterialURLSrc: "",
        additionalMaterialCoverURL: "",
        additionalMaterialCoverURLSrc: "",
        additionalMaterialForDownload: "",
    };
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(field, value){
      if(field === "additionalMaterialCoverURLSrc")
      {
          this.setState({
              additionalMaterialCoverURL:  value,
          })
      }
      if(field === "additionalMaterialURLSrc")
      {
          this.setState({
              additionalMaterialURL:  value,
          })
      }

    this.setState({[field]: value}, () => { this.props.parentStateHandle(field, value) });
  }
  
  componentWillReceiveProps(nextProps){
    this.setState({
        additionalMaterialSubject: nextProps.state.additionalMaterialSubject,
        additionalMaterialGrade: nextProps.state.additionalMaterialGrade ? nextProps.state.additionalMaterialGrade : "",
    });
  }
  
  render(){ 
    const { state } = this.props;
    return(
      <div className="card-alt-2">
        <RadioButtonSubjects 
          textName="additionalMaterialSubject"
          onChange={this.onChange} 
          content={this.props.state.additionalMaterialSubject}
          language={this.props.language} 
          isValidated={state.validateAdditionalMaterialSubject}
        />
        <RadioButtonGrades 
          textName="additionalMaterialGrade"
          onChange={this.onChange} 
          content={this.props.state.additionalMaterialGrade}
          language={this.props.language} 
          isValidated={state.validateAdditionalMaterialGrade}
        />
        <ValidatedTextInput 
          labelID="cl-title-input" 
          textName="additionalMaterialTitle"
          onChange={this.onChange} 
          content={state.additionalMaterialTitle}
          isValidated={state.validateAdditionalMaterialTitle}
          limitFromRules={null}
        />
          <ValidatedTextInput
              labelID="cl-description-input"
              textName="additionalMaterialDescription"
              onChange={this.onChange}
              content={state.additionalMaterialDescription}
              isValidated={state.validateAdditionalMaterialDescription}
              limitFromRules={null}
          />
          <ForDownloadCheck
              textName="additionalMaterialForDownload"
              onChange={this.onChange}
              content={this.props.state.additionalMaterialForDownload}
              language={this.props.language}
              />
            
            <div className='mt-4'></div>
            
          <Card>
              <Card.Header>
                  <Row>
                      <Col style={{textAlign: "center"}}>
                          <b style={{color:'#891d23'}}><FormattedMessage id="cl-url-cover-instructions"/></b>
                      </Col>
                  </Row>
              </Card.Header>
              <Card.Body>
                  <ValidatedTextInput
                      labelID="cl-url-cover-input"
                      textName="additionalMaterialCoverURL"
                      onChange={this.onChange}
                      content={state.additionalMaterialCoverURL}
                      isValidated={state.validateAdditionalMaterialCoverURL}
                      limitFromRules={null}
                  />
                  <AdditionalInfoCoverUpload
                      src={this.props.state.additionalMaterialCoverURLSrc}
                      language={this.props.language}
                      onChange={this.onChange}
                  />
              </Card.Body>
          </Card>

          <div className='mt-4'></div>

          <Card >
              <Card.Header>
                  <Row>
                      <Col style={{textAlign: "center"}}>
                          <b style={{color:'#891d23'}}><FormattedMessage id="cl-url-input-instructions"/></b>
                      </Col>
                  </Row>
              </Card.Header>
              <Card.Body>
                  <ValidatedTextInput
                      labelID="cl-url-input"
                      textName="additionalMaterialURL"
                      onChange={this.onChange}
                      content={state.additionalMaterialURL}
                      isValidated={state.validateAdditionalMaterialURL}
                      limitFromRules={null}
                  />
                  <AdditionalMaterialUpload
                      src={this.props.state.additionalMaterialURLSrc}
                      language={this.props.language}
                      onChange={this.onChange}
                  />
              </Card.Body>
          </Card>


      </div>
    );
  }
}

export default CreateAdditionalMaterialInfoSection;