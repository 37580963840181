import React, { Component } from 'react';
import axios from "../../server/interceptors";
import { FormattedMessage } from 'react-intl';
import { apiSubjectUrl, DEFAULT_LANGUAGE} from '../../config';
import {Col, Row} from 'react-bootstrap';
import SessionManager from "../../utils/authHandling/session-manager";
import DropdownSelector from '../dropdownSelector';

class RadioButtonSubjects extends Component{
  constructor(props){
    super(props);
    this.state = {
      subjectsFromServer: [],
      selectedSubject: ""
    };
    this.onSubjectCall = this.onSubjectCall.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  getTitle(code){
    let title;
    this.state.subjectsFromServer.forEach((item, index) => {
      if(item.code === code){
        title = item.title;
      }
    });
    return title;
  }

  onSubjectCall(){
    const self = this;
    axios.get(apiSubjectUrl, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function (response){
      if(response.status === 200 || response.status === 201){
        const subjectStrings = response.data.map((item) => ({title: item.Name, code: item.Code}));
        self.setState({
          subjectsFromServer: subjectStrings
        });
      } else{
        alert("Error: " + response.status);
      }
    }).catch(error => {
      console.log(error.response.data.Messages);
    });
  }

  componentDidMount(){
    this.onSubjectCall();
  }
  render(){
    return(
      <Row>
        <Col xs={3} md={2}>
        <b><FormattedMessage id="cl-select-subject"/></b>
        </Col>
        {this.props.isValidated ? <span className="text-danger"><FormattedMessage id="va-lesson-radios"/></span>: ""}
        {/* <DynamicRadioChecklist 
          items={this.state.subjectsFromServer}
          parentState={this.state.selectedSubject}
          stateName={this.props.textName}
          onChange={this.props.onChange}
          content={this.props.content}
        /> */}
        <Col xs={9} md={10}>
        <DropdownSelector
          content={this.state.subjectsFromServer} 
          stateName={this.props.textName}
          onChange={this.props.onChange}
          buttonTitle={this.props.content ? this.getTitle(this.props.content) : <FormattedMessage id="cl-select-subject"/>}
        />
        </Col>
      </Row>
    );
  }
}

export default RadioButtonSubjects;