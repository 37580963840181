import React, { Component } from 'react';
import LoginForm from '../../components/loginForm';

import './login.css';
import Navbar from "react-bootstrap/Navbar";
import {LinkContainer} from "react-router-bootstrap";
import image from "../../assets/images/Genials-Logo-White.svg";

class LoginPage extends Component{
    render(){
        return(
            <div className="page">
                <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
                    <Navbar.Brand>
                        <LinkContainer to="./">
                            <img src={image} alt="Genial Lessons v2" height="30px" width="auto"/>
                        </LinkContainer>
                    </Navbar.Brand>
                </Navbar>
                <LoginForm language={this.props.language}/>
            </div>
        );
    }
}

export default LoginPage;