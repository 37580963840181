import React, { Component } from 'react';
import SessionManager from "../../../../utils/authHandling/session-manager";
import DynamicLessonAdditionalMaterialTable from "../../../dynamicLessonAdditionalMaterialTable";
import {Row, Button, Col, Modal} from "react-bootstrap";
import {
    apiLessonAdditionalMaterialCreateUrl,
    apiLessonAdditionalMaterialDeleteUrl,
    apiLessonAdditionalMaterialReadUrl, DEFAULT_LANGUAGE
} from "../../../../config";
import axios from "../../../../server/interceptors";
import {FormattedMessage} from "react-intl";
import {FaPlusCircle} from 'react-icons/fa';
import ViewAdditionalMaterialPage from "../../../../pages/viewAdditionalMaterialPage";
import './style.css';

class LessonAdditionalMaterialSection extends Component{

    constructor(props){
        super(props);
        this.state={
            lessonId: typeof this.props.lessonId !== 'undefined' ? this.props.lessonId : null,
            fromPage: 1,
            limit: 10,
            pageTotals: 1,
            additionalMaterials: [],
            modalOpen: false
        };

        this.loadAdditionalMaterial = this.loadAdditionalMaterial.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    componentDidMount() {
        if(this.state.lessonId !== null) {
            this.loadAdditionalMaterial();
        }
    }

    showModal() {
        this.setState({
            modalOpen: !this.state.modalOpen,
        });
    }

    /**
     * To delete the lesson additional material
     * @param e The id of lesson additional material
     * @returns {Promise<void>}
     */
    async handleDelete(e){
        const response = await axios.post(apiLessonAdditionalMaterialDeleteUrl + "/" + e, null,{
            headers: {
                'Content-Type': 'application/json',                
            }});

        if(response.status === 200){
           this.loadAdditionalMaterial()
        }
    }

    async handleAdd(id, pages){

        if(typeof id === 'undefined' || id === null){
            return;
        }

        let param = {
            LessonId: this.state.lessonId,
            AdditionalMaterialId: id,
            Page: pages,
        };

        const response = await axios.post(apiLessonAdditionalMaterialCreateUrl, param,{
            headers: {
                'Content-Type': 'application/json',                
            }});

        if(response.status === 201){
            this.loadAdditionalMaterial();
            this.showModal();
        }
    }

    /** To get the data from server **/
    async loadAdditionalMaterial(){
        const response = await axios.get(apiLessonAdditionalMaterialReadUrl + "/" + this.state.lessonId + "/" +  this.state.fromPage
            + "/" + this.state.limit, {
            headers: {
                'Content-Type': 'application/json',
            }});

        //This response just returns the rules
        if(response.status === 200){
            let lessonAdditionalMaterial = [];
            response.data.AdditionalMaterials.forEach((item, index) =>{
                lessonAdditionalMaterial.push(item);
            });
            this.setState({
                additionalMaterials: lessonAdditionalMaterial,
                pageTotals: response.data.TotalPages,
            });
            if(response.data.TotalPages < this.state.fromPage){
                this.setState({
                    fromPage: response.data.TotalPages,
                },()=>{
                    if(!this.state.fromPage < 1) {
                        this.previousPage();
                    }
                })
            }
        }else {
            alert("Something wrong while receiving the lesson additional material.");
        }
    }

    /** Changing to previous page **/
    previousPage(){
        this.setState({
            fromPage: this.state.fromPage - 1 <= 0 ? 1 : this.state.fromPage - 1,
        }, function(){
            this.loadAdditionalMaterial();
        });
    }

    /** Changing to next page **/
    nextPage(){
        this.setState({
            fromPage: this.state.fromPage + 1
        }, function(){
            this.loadAdditionalMaterial();
        });
    }

  render(){
    return(
      <div>
        <Row>
            <Col className='mb-2' style={{textAlign: 'right'}}>
                <Button onClick={this.showModal}><FaPlusCircle/>{' '}<FormattedMessage id="am-add"/></Button>
            </Col>
        </Row>
          {this.state.additionalMaterials.length === 0 ? <FormattedMessage id="am-no-data-found"/> :
              <div className='fix-dangerBtn'>
                <DynamicLessonAdditionalMaterialTable data={this.state.additionalMaterials} updateParent={this.handleDelete}/>
                <div className="pageControls">
                    {/* Next page buttons */}
                    <Button disabled={this.state.fromPage  === 1} onClick={this.previousPage}>{'<'}</Button>
                    {this.state.fromPage <= 0 ? this.state.fromPage = 1 : this.state.fromPage}
                    <Button disabled={this.state.pageTotals === this.state.fromPage} onClick={this.nextPage}>{'>'}</Button>
                </div>
              </div>
          }
          <Modal show={this.state.modalOpen} onHide={this.showModal}>
                <ViewAdditionalMaterialPage language={this.props.language} showSelect={true} lessonId={this.state.lessonId} updateParent={this.handleAdd} />
          </Modal>
      </div>
    );
  }
}

export default LessonAdditionalMaterialSection;