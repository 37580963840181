import React, { Component } from 'react';
import { onAddressCall } from '../../server/getLessonAddressCall/lesson-address-call';
import DynamicChecklist from '../dynamicChecklist';
import { FormattedMessage } from 'react-intl';


class CheckboxLessonAddress extends Component{
  
  constructor(props){
    super(props);
    this.state={
      addressFromServer: [],
      selectedAddresses: []
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(field, value){
    this.setState({[field]: value});
    this.props.onChange(field, value);
  }

  componentDidMount(){
    onAddressCall().then(data => {
      this.setState({
        addressFromServer: data
      })
    })
  }

  render(){
    
    return(
      <div>
      <b><FormattedMessage id="cl-addressDisplay"/></b>
        <DynamicChecklist
          items={this.state.addressFromServer}
          parentState={this.state.selectedAddresses}
          stateName="selectedAddresses"
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default CheckboxLessonAddress;