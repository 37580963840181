import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {Col, Row, FormCheck} from "react-bootstrap";

class ForDownloadCheck extends Component{
    constructor(props){
        super(props);
        this.state={
            loading: true,
            additionalMaterialForDownload: this.props.content,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        this.props.onChange("additionalMaterialForDownload", evt.target.checked ? "1": "0")
    }

    render(){
            return(
                <Row>
                    <Col xs={3} md={2}>
                        <b><FormattedMessage id="am-pdf-download"/></b>
                    </Col>
                    {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-radios"/></p> : ""}
                    <Col xs={9} md={10}>
                        <FormCheck
                            onChange={this.handleChange}
                            checked={this.props.content === "1"}
                            stateName={this.props.textName}
                        />
                        <i><small>*<FormattedMessage id="am-pdf-download-instructions"/></small></i>
                    </Col>
                </Row>
            );
    }
}

export default ForDownloadCheck;