import React, { Component } from 'react';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import "./dropdown-selector-style.css";

class DropdownSelector extends Component{
  render(){
    return(
      <div style={{margin: "5px"}}>
        <DropdownButton 
          id="dropdown-basic-button" 
          variant="outline-secondary"
          title={this.props.buttonTitle}
          >
          {this.props.content.length === 0 ? this.props.noContentMessage: 
            this.props.content.map((item) => {
              return <Dropdown.Item 
                key={item.id}
                onClick={() => this.props.onChange(item)}>
                  { item.code + ": " + item.description}
              </Dropdown.Item>
            })
          }
        </DropdownButton>
      </div>
    );
  }
}

export default DropdownSelector;