/**
 * Created by Pedro Ortiz
 * Feb 2021
 * This is based on Genial Web project.
 * Several changes were made to fit the original programmer style of this project.
 */
import React from 'react';
import Select from 'react-select';
import {BeatLoader} from "react-spinners";
import axios from "../../server/interceptors";
import {apiGetPermissions, DEFAULT_LANGUAGE} from "../../config";
import SessionManager from "../../utils/authHandling/session-manager";
import {logoutUser} from "../../utils/authHandling/logout-user";

/**
 * Handles the selection of multiple permissions.
 */
class MultiSelectorPermission extends React.Component{

    constructor(props)
    {
        // Super is always called when defining the constructor of a subclass
        // All React component classes that have a constructor, should start it with a super(props) call.
        super(props);
        this.state = {
            loading: false,
            options: [], // The options to fill the selection
        }
    }

    getPermissions(){
        this.setState({
            loading: true,
        });
        let self = this;
        axios.get(apiGetPermissions, {
            headers: {
                'Content-Type': 'application/json',
            }}).then(function(response){

            if(response.status === 401)
            {
                logoutUser();
            }
            let arrOptions = [];
            if(response.data !== null) {
                for(let i=0; i < response.data.length;i++) {
                    arrOptions.push({
                        value: response.data[i].Id,
                        label: response.data[i].Name,
                        Desc: response.data[i].Desc,
                    });
                }
            }
            self.setState({
                options: arrOptions,
                loading: false,
            });
        }).catch(error => {
            alert(error);
        });
    }

    /**
     * Call the webservices for the users api and fill those selects
     * Component did mount is required for selects with API calls.
     */
    componentDidMount() {
        this.setState({loading: true},()=> {
            // This is promise
            this.getPermissions();
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            value: nextProps.value,
        })
    }

    /**
     * Handles the change event to get the selected value. Do not change this function
     */
    handleChange = (selectedOption) => {
        this.setState({value: selectedOption }, ()=>{
            this.props.updateParent(this.state.value);
        });
    };

    /**
     * Renders the item list
     * @returns {XML} Returns an XML of the selection
     */
    render(){
        if(this.state.loading)
        {
            return(<BeatLoader
                size={10}
                color={"#6bc4ff"}
            />)
        }

        return (
            <Select
                     isMulti
                     onChange={this.handleChange}
                     value={this.props.value}
                     options={this.state.options}/>
        )
    }
}

export default MultiSelectorPermission;