import React, { Component } from 'react';

class SimpleCharCount extends Component{
  render(){
    return(
      <div>
        {this.props.content ? 
          this.props.content.length > this.props.maxChars ?
            <div style={{marginLeft: "20px", color: "red"}}>
              {this.props.content ? this.props.content.length : ""} / {this.props.maxChars ? this.props.maxChars : ""}
            </div>
          :
            <div className='small mt-2'>
              {this.props.content ? this.props.content.length : ""} / {this.props.maxChars ? this.props.maxChars : ""}
            </div>  
        : ""}
      </div>
    );
  }
}

export default SimpleCharCount;