import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {apiSubjectCreateUrl, DEFAULT_LANGUAGE} from '../../config';
import {Button, Container} from 'react-bootstrap';
import './create-subject-page-style.css';
import SubjectDisplayView from "../../components/subjectDisplayView";
import SessionManager from "../../utils/authHandling/session-manager";
import {FaFile} from 'react-icons/fa'

class CreateSubjectPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            id: "",
            langCode: "",
            code: "",
            name: "",
            redirect: false,
        };
        this.onCreateCall = this.onCreateCall.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value});
    }

    onCreateCall(){

        // Validate
        if(this.state.code === ""){
            alert("Error: Subject code is required (Example: MAT, SP, MAT-EN, etc.)./El código de materia es requerido (Ejemplo:MAT, SP, MAT-EN, etc.).");
            return;
        }
        if(this.state.langCode === ""){
            alert("Error: The language of the subject is required. For example, if it is Math in English, then should be en. If the language of the subject is in Spanish, example: Ciencias en español, then enter es. \n Se requiere el idioma de la materia. Por ejemplo, si es Math in English, entonces debería ser en. Si el idioma de la asignatura es español, ejemplo: Ciencias en español, entonces ingrese es..");
            return;
        }
        if(this.state.name === ""){
            alert("Error: The name is required./El nombre es requerido.");
            return;
        }

        this.setState({loading: true}, ()=>{
            // Call the API to insert
            let params = {
                Code: this.state.code,
                LangCode: this.state.langCode,
                Name: this.state.name
            };

            axios.post(apiSubjectCreateUrl, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                    if(response.status === 201)
                    {
                        alert("Subject successfully created./Materia creada satisfactoriamente.");
                        this.setState({
                            redirect: true,
                        })
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                this.setState({loading: false});
            }).catch(error => {

                if(error.response.status === 400)
                {
                    if(typeof error.response.data !== 'undefined' && typeof error.response.data.Messages !== 'undefined' && error.response.data.Messages.length > 0)
                    {
                        let message = "";
                        for(let i=0;i<  error.response.data.Messages.length; i++)
                        {
                            message += error.response.data.Messages[i];
                        }
                        alert(message);
                    }
                }
                else {
                    alert(`Error: ${error}`);
                }
                this.setState({loading: false});
            });
        });
    }

    redirectToEdit(){
        this.setState({redirectToEdit: true})
    }

    render(){
        if(this.state.redirect){
            return <Redirect to='/view-subjects' />
        }

        return(
            <Container fluid className="card-alt">
              <div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <h3><FaFile/><FormattedMessage id="sub-subject"/></h3>
                            {this.state.loading ? <Button disabled variant="success">
                                <FormattedMessage id="sub-create"/></Button> :
                                <Button variant="success" onClick={this.onCreateCall}>
                                    <FormattedMessage id="sub-create"/>
                                </Button>}
                        </div>

                        <SubjectDisplayView
                            isCreation={true}
                            language={this.props.language}
                            additionalMaterialContent={this.state}
                            onChange={this.onChange}
                        />
                    </div>
            </Container>
        );
    }
}

export default CreateSubjectPage;