import axios from "../interceptors";
import {
  apiGetTypes,
  apiGetCodes,
  apiCreateCode,
  apiUpdateCode,
  apiGetCodeById,
  apiGetCodesByTypes,
  apiCreateCodeType,
  apiUpdateCodeType,
  apiGetCodeTypeById,
  apiGetCodesByQuery,
} from "../../config";

const headers = {
  "Content-Type": "application/json",
};

export const createCode = (data) => {
  return axios({
    method: "POST",
    url: `${apiCreateCode}`,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const updateCode = (data) => {
  return axios({
    method: "PUT",
    url: `${apiUpdateCode}`,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const getCodesTypes = async () => {
  const response = await axios.get(apiGetTypes);
  const data = response.data;
  return data;
};

export const getCodeById = async (id) => {
  const response = await axios.get(`${apiGetCodeById}/${id}`);
  const data = response.data;
  return data;
};

export const getCodes = async (data) => {
  const response = await axios.get(
    apiGetCodes + "PageNumber/" + data.tablePage + "/Limit/" + data.limit
  );
  return response.data;
};

export const getCodesByTypesCodes = async (data) => {
  try {
    const response = await axios.post(`${apiGetCodesByTypes}`, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};

export const createCodeType = (data) => {
  return axios({
    method: "POST",
    url: `${apiCreateCodeType}`,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const updateCodeType = (data) => {
  return axios({
    method: "PUT",
    url: `${apiUpdateCodeType}`,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const getCodeTypeId = (id) => {
  return axios.get(`${apiGetCodeTypeById}/${id}`);
};

export const getCodesByQuery = async (
  data = {},
  fromPage = 0,
  limiter = 10
) => {
  const response = await axios.post(
    `${apiGetCodesByQuery}?fromPage=${fromPage}&limiter=${limiter}`,
    data,
    {
      headers: headers,
    }
  );
  return response.data;
};
