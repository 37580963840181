import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { onTemplateCall } from '../../../../server/getLessonTemplateCall/lesson-template-call';

import ModalTemplateContent from '../../../modalTemplateContent';
import ModalPopout from '../../../modalPopout';
import TextAndFieldGenerator from '../../../textAndFieldGenerator';

import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

class CreateLessonDefinicionesSection extends Component{

  constructor(props){
    super(props);
    this.state={
      showModal: false
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeTemplates = this.onChangeTemplates.bind(this);
    this.openTemplateSelection = this.openTemplateSelection.bind(this);
  }

  onChange(content){
    this.props.parentStateHandle("lessonDefinitions", content)
  }

  onChangeTemplates(id, content, selectedId){
    this.setState({selectionChange: true}, function() {
      this.props.parentStateHandle(id, content);
      this.props.selectDefinitionTemplate(selectedId);
    });
  }

  openTemplateSelection(){
    onTemplateCall("DEF", this.props.language).then(data => {
      let selectedTemplateIds = [];
      if(this.props.selectedTemplates !== null || typeof this.props.selectedTemplates !== undefined){
        this.props.selectedTemplates.map((item) => {
          selectedTemplateIds.push({TemplateId: item.TemplateId});
          data.map((itemI, index) => {
            if(itemI.Id === item.TemplateId){
              data[index].isSelected = true;
            }
            return true;
          })
          return true;
        });
      }
      this.setState({showModal: true, modalBodyData: data, selectedTemplateIds: selectedTemplateIds});
    })
  }

  componentDidUpdate(){
    if((this.props.selectedTemplates !== null || this.props.selectedTemplates !== undefined) && this.state.selectedTemplateTitle === undefined && this.state.selectionChange){
      onTemplateCall("DEF", this.props.language).then(data => {
        let selectedDefTemplateid = this.props.selectedTemplates[0] ? this.props.selectedTemplates[0].TemplateId : "";
        data.map((item) => {
          if(selectedDefTemplateid === item.Id){
            this.setState({
              selectedTemplateTitle: item.Name,
              selectedTemplateImage: item.ImgTemplate,
              selectedTemplateDescription: item.Desc.substring(0, 50) + (item.Desc.length > 50 ? "..." : ""),
              selectionChange: false
            });
          }
          return true;
        })
      })
    }
  }


  render(){
    return(
      <div className="card-alt-2 fix-RegionDef">
        <h4><FormattedMessage id="cl-definition-title"/></h4>
        <hr/>
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-richtext"/></p> : ""}
        <p><FormattedMessage id="cl-field-instruction"/></p>

        {/* Button that opens the template selection */}
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Button onClick={this.openTemplateSelection}><FormattedMessage id="cl-select-template"/></Button>
        </div>  

        {this.props.selectedDefinitionTemplate ? 
            <div style={{backgroundColor: "lightgray", margin: "10px", marginLeft: "0px"}}>
              <b style={{margin: "10px"}}><FormattedMessage id="cl-selected-template"/></b>
              <div style={{display: "flex"}}>
                <div>
                  <Image style={{margin: "10px"}} width="200px" src={this.props.selectedDefinitionTemplate.img}/>
                </div>
                <div style={{margin: "10px"}}>
                  <p><b><FormattedMessage id="cl-template-title"/>: </b>{this.props.selectedDefinitionTemplate.title}</p>
                  <p><b><FormattedMessage id="cl-desc"/>: </b>{this.props.selectedDefinitionTemplate.description}</p>
                </div>
              </div>
            </div>
          : 
            <p className='small mt-2 messageNoTemplate'><FormattedMessage id="cl-no-template"/></p>
        }
        
        <TextAndFieldGenerator 
          isDefinitionOrExample
          isVr={this.props.isVr}
          labelId="cl-definition"
          onChange={this.onChange}
          state={this.props.state}
          lessonGrade={this.props.lessonGrade}
          lessonSubject={this.props.lessonSubject}
          limitFromRules={this.props.lessonRules ? this.props.lessonRules.DefinitionMaxLength : null}
          fileRules={this.props.fileRules}
        />
        {this.state.showModal ? 
          <ModalPopout
            title={<FormattedMessage id="cl-select-template"/>}
            body={<ModalTemplateContent handleClose={() => {this.setState({showModal: false})}} data={this.state.modalBodyData} selectedIds={this.state.selectedTemplateIds} onChange={this.onChangeTemplates}/>}
            handleClose={() => {this.setState({showModal: false})}}
          /> 
        :
          ""
        }
      </div>
    );
  }
}

export default CreateLessonDefinicionesSection;