import axios from "axios";
import SessionManager from "../utils/authHandling/session-manager";
import { logoutUser } from '../utils/authHandling/logout-user';
import { DEFAULT_LANGUAGE } from '../config';

const instance = axios.create();

instance.interceptors.request.use((req) => {
    req.headers["Accept-Language"] = localStorage.getItem("i18nextLng") !== null ? localStorage.getItem("i18nextLng") : DEFAULT_LANGUAGE;
    req.headers["Token"] = SessionManager.getSession() ? SessionManager.getSession() : "";
    return req;
});

instance.interceptors.response.use(null, (error) => {
    handleErrorResponse(error);
    console.log("Error", error);
    return Promise.reject(error);
});

export default instance;


export const handleErrorResponse = (error) => {
    let message;

    const ErrorMessages = {
        400: "There was Some Problem, while processing your Request",
        401: "Unauthorized, You are not Allowed",
        403: "Sorry, You are not allowed for This Action",
        404: "API Route is Missing or Undefined",
        405: "API Route Method Not Allowed",
        500: "Server Error, please try again later",
        request: "There is Some Problem With Our Servers, Please Try again Later",
        other: "There was some Problem with your Request, Please Try again Later",
    };

    if (error && error.response) {
        if (error.response.status === 400) {
            const msg =
                error.response.data.Messages && error.response.data.Messages.length
                    ? error.response.data.Messages[0]
                    : ErrorMessages["400"];
            message = msg;
        } else if (error.response.status === 401) {
            const msg =
                error.response.data.Messages && error.response.data.Messages.length
                    ? error.response.data.Messages[0]
                    : ErrorMessages["401"];
            message = msg;
            logoutUser();
        } else if (error.response.status === 403) {
            message = ErrorMessages["403"];
            logoutUser();
        } else if (error.response.status === 404) {
            message = ErrorMessages["404"];
        } else if (error.response.status === 405) {
            message = ErrorMessages["405"];
        } else if (error.response.status >= 500) {
            message = ErrorMessages["500"];
        }
    } else if (error && error.request) {
        message = ErrorMessages.request;
    } else if (error instanceof Error) {
        message = error.message;
    } else {
        message = ErrorMessages.other;
    }
    alert(`Error: ${message}`);
};
