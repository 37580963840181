import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import "./create-code-page.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import { onGradeCall } from "../../server/getLessonGradesCall/lesson-grade-call";
import { onSubjectCall } from "../../server/getLessonSubjectCall/lesson-subject-call";
import {
  createCode,
  getCodeById,
  updateCode,
  getCodesTypes,
} from "../../server/getCodeTypesCall/code-type-call";
import { FaCodeBranch } from "react-icons/fa";
import Alert from "react-bootstrap/Alert";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateCodePage = () => {
  const history = useHistory();
  let query = useQuery();
  let id = query.get("id");
  const [data, setData] = useState({
    code: "",
    description: "",
  });
  const [grades, setGrades] = useState([]);
  const [gradesSelecteds, setGradesSelecteds] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectsSelecteds, setSubjectsSelecteds] = useState([]);
  const [codetypes, setCodeTypes] = useState([]);
  const [codeTypeSelected, setCodeTypeSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrorMsg, setErroShow] = useState(false);

  useEffect(() => {
    getCodeTypes();
    getGrades();
    getSubjects();
    if (id) {
      getCode();
    }
  }, []);

  const getCode = async () => {
    try {
      const res = await getCodeById(id);
      const codeTypes = await getCodeTypes();
      const type = codeTypes.find((item) => item.value === res.TypeId);
      const grades = await getGrades();
      const gradesIds = res.CodeLevelModels.map((item) => item.LevelCode);
      const gradesSelecteds = grades.filter((item) =>
        gradesIds.includes(item.value)
      );
      const subjects = await getSubjects();
      const subjectsIds = res.CodeSubjectModels.map((item) => item.SubjectCode);
      const subjectsSelecteds = subjects.filter((item) =>
        subjectsIds.includes(item.value)
      );
      setCodeTypeSelected(type);
      setGradesSelecteds(gradesSelecteds);
      setSubjectsSelecteds(subjectsSelecteds);
      setData({
        code: res.Code,
        description: res.Description,
      });
    } catch (error) {
      alert(error);
    }
  };

  const getCodeTypes = async () => {
    try {
      const data = await getCodesTypes();
      const codeTypes = data.map((item) => ({
        value: item.Id,
        label: item.Name,
      }));
      setCodeTypes(codeTypes);
      return codeTypes;
    } catch (error) {
      alert(error);
    }
  };

  const getGrades = async () => {
    try {
      const data = await onGradeCall();
      const grades = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      setGrades(grades);
      return grades;
    } catch (error) {
      alert(error);
    }
  };

  const getSubjects = async () => {
    try {
      const data = await onSubjectCall();
      const subjects = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      setSubjects(subjects);
      return subjects;
    } catch (error) {
      alert(error);
    }
  };

  const handleSubmit = async () => {
    if (!validate()) {
      setErroShow(true);
      return;
    }
    if (id) {
      update();
    } else {
      create();
    }
  };

  const create = async () => {
    const req = {
      Code: data.code,
      Description: data.description,
      RelationCodeId: codeTypeSelected && Number(codeTypeSelected.value),
      TypeId: codeTypeSelected && Number(codeTypeSelected.value),
      CodeLevelModels: gradesSelecteds.map((item) => ({
        LevelCode: item.value,
      })),
      CodeSubjectModels: subjectsSelecteds.map((item) => ({
        SubjectCode: item.value,
      })),
    };
    setLoading(true);
    try {
      const response = await createCode(req);
      if (response.status === 201 || response.status === 200) {
        alert("Code successfully created./Codigo creado satisfactoriamente.");
        history.push(`/view-codes`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const update = async () => {
    const req = {
      Id: id,
      Code: data.code,
      Description: data.description,
      RelationCodeId: codeTypeSelected && Number(codeTypeSelected.value),
      TypeId: codeTypeSelected && Number(codeTypeSelected.value),
      CodeLevelModels: gradesSelecteds.map((item) => ({
        LevelCode: item.value,
      })),
      CodeSubjectModels: subjectsSelecteds.map((item) => ({
        SubjectCode: item.value,
      })),
    };
    setLoading(true);
    try {
      const response = await updateCode(req);
      if (response.status === 201 || response.status === 200) {
        alert(
          "Code successfully updated./Codigo actualizado satisfactoriamente."
        );
        history.push(`/view-codes`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value.trim().length > 0
          ? event.target.value
              .replace("(%27)|(')|(--)|(%23)|(#)", "")
              .replace("((%3D)|(=))[^\n]*((%27)|(')|(--)|(%3B)|(;))", "")
              .replace("w*((%27)|('))((%6F)|o|(%4F))((%72)|r|(%52))", "")
              .replace("((%27)|('))union", "")
          : "",
    });
  };

  const validate = () => {
    const errors = {};
    let formValid = true;

    if (!data.code) {
      formValid = false;
      errors.name = "The code field is required";
    }
    if (!data.description) {
      formValid = false;
      errors.description = "The description field is required";
    }
    if (!codeTypeSelected) {
      formValid = false;
      errors.duration = "The code type field is required";
    }
    if (!gradesSelecteds.length) {
      formValid = false;
      errors.gradeSelected = "The grade field is required";
    }
    if (!subjectsSelecteds.length) {
      formValid = false;
      errors.subjectSelected = "The subject is required";
    }
    setErrors(errors);
    return formValid;
  };

  return (
    <>
      <Container fluid className="card-alt">
        <div className="conten-unit-page">
          <h3>
            <FaCodeBranch />
            <FormattedMessage id="ct-create-code" />
          </h3>
          {id ? (
            <Button variant="success" onClick={handleSubmit} disabled={loading}>
              <FormattedMessage id="code-button-upload" />
            </Button>
          ) : (
            <Button variant="success" onClick={handleSubmit} disabled={loading}>
              <FormattedMessage id="code-button-create" />
            </Button>
          )}
        </div>
        {showErrorMsg ? (
          <div style={{ marginTop: "2em" }}>
            <Row>
              <Col>
                <Alert
                  variant="danger"
                  onClose={() => setErroShow(false)}
                  dismissible
                >
                  <Alert.Heading>
                    <FormattedMessage id="error-verify-fields" />
                  </Alert.Heading>
                  <p>
                    <FormattedMessage id="error-verify-fields-user" />
                  </p>
                </Alert>
              </Col>
            </Row>
          </div>
        ) : null}

        <div className="card-alt-2 mt-4">
          <Form>
            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="tb-code" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Form.Control
                    type="text"
                    onChange={handleInputChange}
                    name="code"
                    value={data.code}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="tb-type" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Select
                    onChange={(selected) => setCodeTypeSelected(selected)}
                    options={codetypes}
                    placeholder="Seleccione..."
                    value={codeTypeSelected}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-description" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Form.Control
                    as="textarea"
                    type="text"
                    onChange={handleInputChange}
                    name="description"
                    value={data.description}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-level" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <MultiSelect
                    value={gradesSelecteds}
                    onChange={(selecteds) => setGradesSelecteds(selecteds)}
                    options={grades}
                    placeholder="Seleccione..."
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-subject" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <MultiSelect
                    value={subjectsSelecteds}
                    onChange={(selecteds) => setSubjectsSelecteds(selecteds)}
                    options={subjects}
                    placeholder="Seleccione..."
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CreateCodePage;
