import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CommentTableInstance from './comment-table-instance';
import './style.css';
import ModalCommentCreate from './modal-comment-create';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

class CommentTable extends Component{

  constructor(props){
    super(props);
    this.state={
      commentDataArray: [],
      modalCreateOpen: false
    };
    this.closeCreateModal = this.closeCreateModal.bind(this);
    this.updateAReceivedMessageAsRead = this.updateAReceivedMessageAsRead.bind(this);
    this.updateMessageCompleted = this.updateMessageCompleted.bind(this);
  }

  closeCreateModal(){
    this.setState({modalCreateOpen: false});
  }

  updateAReceivedMessageAsRead(id){
    let newArray = this.props.messagesReceived;
    newArray[id].read = "1";
    this.setState({MessagesReceived: newArray});
  }

  updateMessageCompleted(id){
    let newArray = this.props.messagesReceived;
    newArray[id].completed = "1";
    this.setState({MessagesReceived: newArray});
  }

  render(){
    return(
      <div>
        <div className='text-right'>
          <Button onClick={()=> {this.setState({modalCreateOpen: !this.state.modalCreateOpen})}}>+  <FormattedMessage id="cs-create"/></Button>{' '}
          <Button onClick={this.props.loadComments} variant="warning"> <FontAwesomeIcon icon={faSync} /></Button>
        </div>
        
        <Tabs className='RegionTabs' style={{marginTop: "10px"}} defaultActiveKey="Received" id="comment-tabs">
          <Tab eventKey="Received" title="Recibidos">
            <CommentTableInstance 
              updateCompleted={this.updateMessageCompleted} 
              updateRead={this.updateAReceivedMessageAsRead} 
              data={this.props.messagesReceived} 
              commentType="received" 
              language={this.props.language}
              entityId={this.props.entityId}
              entityType={this.props.entityType}
              loadComments={this.props.loadComments}
            />
          </Tab>
          <Tab eventKey="Sent" title="Enviados">
            <CommentTableInstance 
              data={this.props.messagesSent} 
              commentType="sent" 
              language={this.props.language}
              loadComments={this.props.loadComments}
            />
          </Tab>
        </Tabs>
        
        <ModalCommentCreate 
          modalOpen={this.state.modalCreateOpen}
          closeModal={this.closeCreateModal}
          entityId={this.props.entityId}
          entityType={this.props.entityType}
          language={this.props.language}
          loadComments={this.props.loadComments}
        />
      </div>
    );
  }
}

export default CommentTable;