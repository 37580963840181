import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import AddedSimpleField from '../addedSimpleField';
import {FaPlusCircle} from 'react-icons/fa';

import './simple-field-generator.css';

class SimpleFieldGenerator extends Component{
  constructor(props){
    super(props);
    this.createNewField = this.createNewField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(index, value){
    let content = this.props.state;
    content[index] = value;
    this.props.setParentState(content);
  }

  createNewField(){
    this.props.setParentState(this.props.state.concat({"Desc": ""}));
  }

  removeField(index){
    let content = this.props.state;
    content.splice(index, 1);
    this.props.setParentState(content);
  }

  render(){
    return(
      <div>
        <div>
          {this.props.state ? this.props.state.map((item, index) => {
            return(
              <AddedSimpleField 
                key={index}
                removeField={this.removeField}
                labelID={this.props.titleId}
                index={index}
                onChange={this.onChange}
                content={item.Desc}
              />
            )
          }): ""}
        </div>
        <hr/>
        <div className="addedFieldButtonContainer">
          <Button className="success addedFieldButton" onClick={this.createNewField}> <FaPlusCircle/>{' '}<FormattedMessage id="cl-objectives-add-button"/></Button>
        </div>
      </div>
    );
  }
}

export default SimpleFieldGenerator;