import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextAreaInput from '../textAreaInput';
import { FormattedMessage } from 'react-intl';
import { getCommentUsers } from '../../server/getCommentsUsers/get-comment-users';
import { createCommentCall } from '../../server/postComment/post-comment';
import Select from 'react-select'

class ModalCommentCreate extends Component{
  
  constructor(props){
    super(props);
    this.state={
      userName: "",
      users: [],
      messageContent: null,
      userRecipientId: null
    };
    this.sendComment = this.sendComment.bind(this);
    this.createComment = this.createComment.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.selectUser = this.selectUser.bind(this);
  }

  sendComment(commentModel){
    if(createCommentCall(this.props.language, commentModel).then(() => {
      this.props.loadComments();
    })){
      this.props.closeModal();
    }
  }

  selectUser(e){
    this.setState({
      userRecipientId: e.value,
    });
  }

  createComment(){
    const commentModel = {
      UserToId: this.state.userRecipientId,
      EntityId: this.props.entityId,
      Type: this.props.entityType,
      Comment: this.state.messageContent
    };
    let commentComplete = true;
    //User
    if(commentModel.UserTold === null){
      this.setState({userValidFlag: true});
      commentComplete = false;
    }
    //Message
    if(commentModel.Comment === null){
      this.setState({messageValidFlag: true});
      commentComplete = false;
    }

    if(commentComplete){
      this.sendComment(commentModel);
    }
  }

  onChangeMessage(content){
    this.setState({messageContent: content});
  }

  componentWillMount(){
    getCommentUsers().then((data)=>{
      let options = [];
      for(let i=0; i < data.length;i++)
      {
         options.push({
           value: data[i].UserId,
           label: data[i].GivenName + " " + data[i].Surname
         })
      }
      this.setState({
        users: options,
      });
    })
  }

  render(){


    return(
      <div>
        <Modal show={this.props.modalOpen} onHide={this.props.closeModal}>
          <Modal.Title style={{marginLeft: "10px"}}><FormattedMessage  id="cs-comment"/> {this.props.messageId}</Modal.Title>
          <Modal.Body>
            <Row>
              <Col>
                {this.state.userValidFlag ? <p style={{color: "red"}}>*Required</p> : ""}<FormattedMessage id="cs-for"/> {this.state.userName ? this.state.userName : ""}
              </Col>
            </Row>
            <Row>
              <Col>
                <Select options={this.state.users} onChange={this.selectUser} />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{backgroundColor: "rgba(0,0,0,0.2)", height: "200px", padding: "20px", marginTop: "10px"}}>
                  {this.state.messageValidFlag ? <p style={{color: "red"}}>*Required</p> : ""}
                  <TextAreaInput textType="TextArea" onChange={this.onChangeMessage}/>
                  <Button style={{marginTop: '10px'}} onClick={this.createComment}><FormattedMessage id="cs-send"/></Button>
                </div>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.closeModal}>
              <FormattedMessage id="option-close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ModalCommentCreate;