import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from 'react-bootstrap/Alert';
import axios from "../../server/interceptors";
import {
  apiGetUserDataUrl,
  apiGetUserRoles,
  apiRegister,
  apiUserUpdate,
  apiPasswordUpdate,
  DEFAULT_LANGUAGE
} from '../../config';
import { FormattedMessage } from 'react-intl';
import SessionManager from "../../utils/authHandling/session-manager";
import { FaSearch , FaUsers } from 'react-icons/fa';

/** Edited by POR 2019.08.08 **/
class UserPage extends Component{
  constructor(props){
    super(props);
    this.state={
      roles: [], // The user roles
      showErrorMsg: false, // To show or hide error message when submitting an user
      showErrorMsgPasswordUpdate: false,
      selectedRole: null, // The role ID
      selectedRoleName: null, // The Role name
      user: {
        passwordRetype: null,
        password: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        username: null
      },
      selectedUserId: typeof this.props.location.data !== 'undefined' && typeof this.props.location.data.userId !== 'undefined' ?
          this.props.location.data.userId : null // The User ID to edit. If null, then is a register
    };
    this.getUserData = this.getUserData.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.selectRole = this.selectRole.bind(this);
    this.setErroShow = this.setErroShow.bind(this);
    this.firstNameChange = this.firstNameChange.bind(this);
    this.lastNameChange = this.lastNameChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.passwordRetypeChange = this.passwordRetypeChange.bind(this);
    this.phoneChange = this.phoneChange.bind(this);
    this.usernameChange = this.usernameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.saveUserData = this.saveUserData.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.setErrorPassword = this.setErrorPassword.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);

    console.log("HERE ************************")
  }

  /** The call to get user roles **/
  getRoles(){

    axios.get(apiGetUserRoles, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then((response)=>{
      if(response.status === 200 || response.status === 201){
        let roles = [];
        response.data.map((item) => {
          return roles.push({id: item.Id, name: item.Name});
        });
        this.setState({roles: roles});
      }
    });
  }

  /** To show or hide error message when submit (prevalidation) **/
  setErroShow(show){

    this.setState({showErrorMsg: show})
  }

  /** To show or hide error message when update password (prevalidation) **/
  setErrorPassword(show){
    this.setState({showErrorMsgPasswordUpdate: show})
  }

  /** Edited by POR 2023.02.16, since the users were complaining that there was an error (400), but they
   * did not have any idea what that means, so now we are showing the error details.
   * @param error
   */
  showErrorMessage(error){
    if (error.response != null && error.response.data != null && error.response.data.Messages != null && error.response.data.Messages.length > 0)
    {
      let message = "";
      for(let i=0; i < error.response.data.Messages.length; i++)
      {
        message += error.response.data.Messages[i] + "\n"
      }
      alert(message);
    }
    else
    {
      alert(error);
    }
  }

  /** Action when selecting a role */
  selectRole(id, name){
    this.setState({
      selectedRole: id,
      selectedRoleName: name
    });
  }

  /** Call to get user data **/
  getUserData(){

    const params = {
      "PageNumber": 1,
      "Limit": 1,
      "UserId": this.state.selectedUserId,
    };

    axios.post(apiGetUserDataUrl, params, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then((response)=>{
      if(response.status === 200 || response.status === 201){

        if(response.data.UserInfo !== null) {
          this.setState({user:
          {
            userId: response.data.UserInfo[0].UserId,
            email: response.data.UserInfo[0].Email,
            username: response.data.UserInfo[0].Username,
            phone: response.data.UserInfo[0].Phone,
            firstName: response.data.UserInfo[0].FullName.GivenName,
            lastName: response.data.UserInfo[0].FullName.Surname
          },
            selectedRole: response.data.UserInfo[0].Roles[0].RoleId,
            selectedRoleName: response.data.UserInfo[0].Roles[0].RoleName
          })
        }
      }
    }).catch(error => {
      alert(error);
    });

  }

  componentDidMount(){
    if(this.state.selectedUserId !== null) {
      this.getUserData();
    }
    this.getRoles();
  }

  /** Action when user name changes **/
  usernameChange(event)
  {
    this.setState({
      user:{
        passwordRetype: this.state.user.passwordRetype,
        password: this.state.user.password,
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName,
        phone: this.state.user.phone,
        email: this.state.user.email,
        username: event.target.value
      }
    });
  }

  /** Action when password change **/
  passwordChange(event)
  {
    this.setState({
      user:{
        passwordRetype: this.state.user.passwordRetype,
        password: event.target.value,
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName,
        phone: this.state.user.phone,
        email: this.state.user.email,
        username: this.state.user.username
      }
    });
  }

  /** Action when password retype change **/
  passwordRetypeChange(event)
  {
    this.setState({
      user:{
        passwordRetype: event.target.value,
        password: this.state.user.password,
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName,
        phone: this.state.user.phone,
        email: this.state.user.email,
        username: this.state.user.username
      }
    });
  }

  /** Action when first name change **/
  firstNameChange(event)
  {
    this.setState({
      user:{
        passwordRetype: this.state.user.passwordRetype,
        password: this.state.user.password,
        firstName: event.target.value,
        lastName: this.state.user.lastName,
        phone: this.state.user.phone,
        email: this.state.user.email,
        username: this.state.user.username
      }
    });
  }

  /** Action when lastname change **/
  lastNameChange(event)
  {
    this.setState({
      user:{
        passwordRetype: this.state.user.passwordRetype,
        password: this.state.user.password,
        firstName: this.state.user.firstName,
        lastName: event.target.value,
        phone: this.state.user.phone,
        email: this.state.user.email,
        username: this.state.user.username
      }
    });
  }

  /** Action when phone changes **/
  phoneChange(event)
  {
    this.setState({
      user:{
        passwordRetype: this.state.user.passwordRetype,
        password: this.state.user.password,
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName,
        phone: event.target.value,
        email: this.state.user.email,
        username: this.state.user.username
      }
    });
  }

  /** Action when email changes **/
  emailChange(event)
  {
    this.setState({
      user:{
        passwordRetype: this.state.user.passwordRetype,
        password: this.state.user.password,
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName,
        phone: this.state.user.phone,
        email: event.target.value,
        username: this.state.user.username
      }
    });
  }

  /** To save user data **/
  saveUserData(){
    // First, we must validate

    // It's an update
    if(typeof this.state.selectedUserId !== 'undefined' && this.state.selectedUserId !== null)
    {
      // Validate fields
      if(this.state.selectedRole === null
          || this.state.user === null
          || this.state.user.username === ""
          || this.state.user.username === null
          || this.state.user.email === ""
          || this.state.user.email === null
          || this.state.user.phone === ""
          || this.state.user.phone === null
          || this.state.user.phone === ""
          || this.state.user.phone === null
          || this.state.user.firstName === ""
          || this.state.user.firstName === null
          || this.state.user.lastName === ""
          || this.state.user.lastName === null
      )
      {
        this.setErroShow(true);
      }
      else
      {
        const params = {
          "Username": this.state.user.username,
          "Email": this.state.user.email,
          "RoleId": this.state.selectedRole,
          "FirstName": this.state.user.firstName,
          "LastName": this.state.user.lastName,
          "Phone": this.state.user.phone,
          "Password": this.state.user.password,
          "PasswordRetype": this.state.user.passwordRetype,
        };
        console.log("HERE &**************************")
        console.log(localStorage.getItem('i18nextLng'));
        // Update the fields
        axios.post(apiUserUpdate, params, {
          headers: {
            'Content-Type': 'application/json',            
          }}).then((response)=>{
          if(response.status === 200 || response.status === 201) {
            alert(response.data.Messages);
          }
        }).catch(error => {
          this.showErrorMessage(error)
        });
      }
    }
    else // It's a register
    {
      // Validate fields
      if(this.state.selectedRole === null
          || this.state.user === null
          || this.state.user.username === ""
          || this.state.user.username === null
          || this.state.user.email === ""
          || this.state.user.email === null
          || this.state.user.phone === ""
          || this.state.user.phone === null
          || this.state.user.phone === ""
          || this.state.user.phone === null
          || this.state.user.firstName === ""
          || this.state.user.firstName === null
          || this.state.user.lastName === ""
          || this.state.user.lastName === null
          || this.state.user.password === null
          || this.state.user.passwordRetype == null
          || this.state.user.password !== this.state.user.passwordRetype
      )
      {
        this.setErroShow(true);
      }
      else
      {
        this.setErroShow(false);

        console.log(this.state);
        // Do the registration
        const params = {
          "Username": this.state.user.username,
          "Email": this.state.user.email,
          "RoleId": this.state.selectedRole,
          "FirstName": this.state.user.firstName,
          "LastName": this.state.user.lastName,
          "Phone": this.state.user.phone,
          "Password": this.state.user.password,
          "PasswordRetype": this.state.user.passwordRetype
        };

        axios.post(apiRegister, params, {
          headers: {
            'Content-Type': 'application/json',            
          }}).then((response)=>{

           if(response.status === 200 || response.status === 201){

             alert(response.data.Messages);

              // Reset fields
              this.setState({
                selectedRole: null, // The role ID
                selectedRoleName: null, // The Role name
                user: {
                  passwordRetype: null,
                  password: null,
                  firstName: null,
                  lastName: null,
                  phone: null,
                  email: null,
                  username: null
                }
              })
            }
        }).catch(error => {
          this.showErrorMessage(error)
        });
      }
    }
  }

  /** To update Password **/
  updatePassword(){

    // Fist, validate
    if(this.state.user.password === null || this.state.user.passwordRetype == null || this.state.user.username == null)
    {
      this.setErrorPassword(true);
    }
    else
    {
      // Update
      const params = {
        "UserId": this.state.selectedUserId,
        "Password": this.state.user.password,
        "PasswordRetype": this.state.user.passwordRetype
      };

      axios.post(apiPasswordUpdate, params, {
        headers: {
          'Content-Type': 'application/json',          
        }}).then((response)=>{
        if(response.status === 200 || response.status === 201){
          alert(response.data.Messages);
        }

      }).catch(error => {
        alert(error);
      });
    }
  }

  render(){
    return(
      <div className="card-alt">
        <div>
          <Row>
            <Col><h3><FaUsers/><FormattedMessage id="u-title"/></h3></Col>
          </Row>
          <Row>
            <Col><hr/></Col>
          </Row>
          {/* Username */}
          <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Row>
              <Col>
                <Form.Group controlId="formUsername">
                  <Form.Label><FormattedMessage id="u-username"/></Form.Label>
                  {
                    this.state.selectedUserId !== 'undefined' && this.state.selectedUserId !== null ?
                    <Form.Control type="text" disabled value={
                      this.state.user !== 'undefined'
                      && this.state.user !== null
                      && this.state.user.username !== 'undefined'
                      && this.state.user.username !== null ? this.state.user.username : ''
                    } /> :  <Form.Control type="text"  value={
                      this.state.user !== 'undefined'
                      && this.state.user !== null
                      && this.state.user.username !== 'undefined'
                      && this.state.user.username !== null ? this.state.user.username : ''
                    } onChange={this.usernameChange} />
                  }
                  <Form.Text className="text-muted">
                    <FormattedMessage id="u-username-rules"/>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            {/* Email */}
            <Row>
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label><FormattedMessage id="u-email"/></Form.Label>
                  <Form.Control type="email" value={
                    this.state.user !== 'undefined'
                    && this.state.user !== null
                    && this.state.user.email !== 'undefined'
                    && this.state.user.email !== null ? this.state.user.email : ''
                  } onChange={this.emailChange}/>
                </Form.Group>
              </Col>
            </Row>

            {/* Roles */}
            <Row>
              <Col>
                <Form.Group controlId="formRole">
                  <Form.Label><FormattedMessage id="u-role"/></Form.Label>
                  <DropdownButton variant="outline-secondary" id="dropdown-basic-button" title={<FormattedMessage id="u-select-role"/>}>
                    {
                      this.state.roles.map((item) => {
                        return <Dropdown.Item
                            key={item.id} onClick={() => this.selectRole(item.id, item.name)}>{item.name}</Dropdown.Item>
                      })
                    }
                  </DropdownButton>
                  {this.state.selectedRoleName}
                </Form.Group>
              </Col>
            </Row>


            {/* Password */}
            { this.state.selectedUserId === 'undefined' || this.state.selectedUserId === null ?
              <Row>
                <Col>
                  <Form.Group controlId="formPassword">
                    <Form.Label><FormattedMessage id="u-password"/></Form.Label>
                    <Form.Control type="password" onChange={this.passwordChange} value={this.state.user.password !== null ?
                        this.state.user.password : ''}/>
                  </Form.Group>
                </Col>
              </Row> : null
            }
            { this.state.selectedUserId === 'undefined' || this.state.selectedUserId === null ?
              <Row>
                <Col>
                  <Form.Group controlId="formPasswordRetype">
                    <Form.Label><FormattedMessage id="u-password-retype"/></Form.Label>
                    <Form.Control type="password" onChange={this.passwordRetypeChange} value={
                      this.state.user.passwordRetype !== null ? this.state.user.passwordRetype : ''} />
                  </Form.Group>
                </Col>
              </Row> : null
            }

            {/* Name */}
            <Row>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label><FormattedMessage id="u-first-name"/></Form.Label>
                  <Form.Control type="text" value={
                    this.state.user !== 'undefined'
                    && this.state.user !== null
                    && this.state.user.firstName !== 'undefined'
                    && this.state.user.firstName !== null ? this.state.user.firstName : ''
                  } onChange={this.firstNameChange} />
                </Form.Group>
              </Col>
            </Row>

            {/* Last Name */}
            <Row>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label><FormattedMessage id="u-last-name"/></Form.Label>
                  <Form.Control type="text" value={
                    this.state.user !== 'undefined'
                    && this.state.user !== null
                    && this.state.user.lastName !== 'undefined'
                    && this.state.user.lastName !== null ? this.state.user.lastName : ''
                  } onChange={this.lastNameChange} />
                </Form.Group>
              </Col>
            </Row>

            {/* Phone */}
            <Row>
              <Col>
                <Form.Group controlId="formPhone">
                  <Form.Label><FormattedMessage id="u-phone"/></Form.Label>
                  <Form.Control type="text"  value={
                    this.state.user !== 'undefined'
                    && this.state.user !== null
                    && this.state.user.phone !== 'undefined'
                    && this.state.user.phone !== null ? this.state.user.phone : ''
                  } onChange={this.phoneChange} />
                </Form.Group>
              </Col>
            </Row>

            {/* Error */}
            {this.state.showErrorMsg ?
              <Row>
                <Col>
                  <Alert variant="danger" onClose={() => this.setErroShow(false)} dismissible>
                    <Alert.Heading>
                      <FormattedMessage id="error-verify-fields"/>
                    </Alert.Heading>
                    <p>
                      <FormattedMessage id="error-verify-fields-user"/>
                    </p>
                  </Alert>
                </Col>
              </Row> : null
            }
            {/* Submit Button */}
            <Row>
              <Col>
                <Button variant="success" onClick={this.saveUserData}><FormattedMessage id="button-submit" /></Button>
              </Col>
            </Row>

          </Col>

            {/******************* PASSWORD RESET ****************************/}
          <Col xs={12} sm={12} md={6} lg={6}>
            {/* Password */}
            { this.state.selectedUserId !== 'undefined' && this.state.selectedUserId !== null ?
                <Row>
                  <Col>
                    <Form.Group controlId="formPassword">
                      <Form.Label><FormattedMessage id="u-password"/></Form.Label>
                      <Form.Control type="password" onChange={this.passwordChange}
                                    value={this.state.user.password !== null ? this.state.user.password : ''} />
                    </Form.Group>
                  </Col>
                </Row> : null
            }
            { this.state.selectedUserId !== 'undefined' && this.state.selectedUserId !== null ?
                <Row>
                  <Col>
                    <Form.Group controlId="formPasswordRetype">
                      <Form.Label><FormattedMessage id="u-password-retype"/></Form.Label>
                      <Form.Control type="password"  onChange={this.passwordRetypeChange}
                                    value={this.state.user.passwordRetype !== null ? this.state.user.passwordRetype : ''}/>
                    </Form.Group>
                  </Col>
                </Row> : null
            }
            {/* Error */}
            {this.state.showErrorMsgPasswordUpdate ?
                <Row>
                  <Col>
                    <Alert variant="danger" onClose={() => this.setErrorPassword(false)} dismissible>
                      <Alert.Heading>
                        <FormattedMessage id="error-verify-fields"/>
                      </Alert.Heading>
                      <p>
                        <FormattedMessage id="error-password-match-required"/>
                      </p>
                    </Alert>
                  </Col>
                </Row> : null
            }
            {/* Submit button */}
            { this.state.selectedUserId !== 'undefined' && this.state.selectedUserId !== null ?
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Button variant="success" onClick={this.updatePassword}>
                          <FormattedMessage id="button-change-password" /></Button>
                      </Col>
                    </Row>
                  </Col>
                </Row> : null
            }

            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

export default UserPage;