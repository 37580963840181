import axios from "../interceptors";
import {
  apiCreateUnit,
  apiUpdateUnit,
  apiGetUnitById,
  apiGetUnitsByGradeAndSubject,
} from "../../config";

const headers = {
  "Content-Type": "application/json",
};

export const createUnit = (data) => {
  return axios({
    method: "POST",
    url: `${apiCreateUnit}`,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const updateUnit = (data) => {
  return axios({
    method: "POST",
    url: `${apiUpdateUnit}`,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const geUnitById = (id) => {
  return axios(`${apiGetUnitById}/${id}`, { headers: headers });
};

export const getUnitsByGradeAndSubject = (level, subject) => {
  return axios(`${apiGetUnitsByGradeAndSubject}/${level}/${subject}`, {
    headers: headers,
  });
};
