import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import "./lesson-pdf-display.css";
import Button from 'react-bootstrap/Button';
import { getLessonPdfLink } from '../../server/getLessonPdfLinkCall/get-lesson-pdf-link';
import { Document, Page, pdfjs } from "react-pdf";
import SessionManager from "../../utils/authHandling/session-manager";
import {ROLE_STR_ADMIN,ROLE_STR_SUB_ADMIN} from "../../config";
import Spinner from 'react-bootstrap/Spinner';
import { Row,Col } from 'react-bootstrap';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class LessonPdfDisplay extends Component{
    constructor(props){
        super(props)
        this.state = {
            roles: SessionManager.getSession() !== null ? SessionManager.getSessionDetails().Roles : [],
            lessonId: props.lessonId != null ? props.lessonId : sessionStorage.getItem("lastSearch"),
            language: props.language,
            numPages: 0,
            pageNumber: 1,
            pdfLink: '',
            loading: false,
        }
    }
   componentDidMount = () => {
       var self = this;
    getLessonPdfLink(self.state.language, self.state.lessonId).then((response) =>{
        self.setState({pdfLink: response});
      }).catch((error) => {
        console.log(error.response.data.Messages);
      });
   }
   //once the document is loaded save de number of pages
   onDocumentLoadSuccess = (pages) => {
       this.setState({numPages: pages._pdfInfo.numPages});
   }
   //function to verify if user can download PDF
   IsRoleAllowedToDownload = (RoleList) => {
       var allowedRoles = [ROLE_STR_ADMIN,ROLE_STR_SUB_ADMIN];
       return allowedRoles.some(role => RoleList.includes(role))
   };
   nextPage = () => {
       var self = this;
    if (this.state.pageNumber < this.state.numPages) {
        self.setState({pageNumber: this.state.pageNumber + 1});
      }
   };
   prevPage = () => {
       var self = this;
    if (this.state.pageNumber > 1) {
        self.setState({pageNumber: this.state.pageNumber - 1});
      }
    };
    render(){
        const roles = this.state.roles;
        return  (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={12}>
                    {this.IsRoleAllowedToDownload(roles) &&
                     <a href={this.state.pdfLink} download target="_blank" className="downloadPdfLink">
                         <Button className="downloadPdfButton">
                            <span className="fa fa-file-pdf-o"></span>
                         <FormattedMessage id="vp-download-pdf"></FormattedMessage>
                         </Button>
                     </a>
                    }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                    <div className="pdfContainer">
                <Document
                   file={this.state.pdfLink}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    onContextMenu={(e) => e.preventDefault()}
                    className="pdf-container">
                 <Page pageNumber={this.state.pageNumber} />
                </Document>
                </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                    <div className="pageControls">
                  <Button disabled={this.state.pageNumber === 1} onClick={this.prevPage}>{'<'}</Button>
                 {this.state.pageNumber}
                  <Button disabled={this.state.pageNumber === this.state.numPages} onClick={this.nextPage}>{'>'}</Button>
                 </div>
                    </Col>
                </Row>
            </Col>
        </Row>
      );
    }
}
export default LessonPdfDisplay;