import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import DynamicTable from "../../components/dynamicTableTypes";
import { FaCodeBranch } from "react-icons/fa";
import "./view-type-page.css";
import SpinnerLoader from "../../components/spinnerLoader/spinner-loader";
import { getCodesTypes } from "../../server/getCodeTypesCall/code-type-call";

class ViewTypePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableData: [],
      tablePage: 1,
      totalItems: 0,
    };
  }

  componentDidMount() {
    this.getCodeTypes();
  }

  getCodeTypes = async () => {
    this.setState({
      loading: true,
    });
    try {
      const data = await getCodesTypes();
      const dataReceived = data.map((item) => ({
        id: item.Id,
        type: item.Name,
      }));
      this.setState({
        tableData: dataReceived,
        tablePage: 1,
        totalItems: dataReceived.length,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <Container fluid className="card-alt">
        <Row>
          <Col xs={12}>
            <h3>
              <FaCodeBranch />
              <FormattedMessage id="type-code" />{" "}
            </h3>
          </Col>
        </Row>
        {this.state.loading ? (
          <div style={{ marginTop: "40px" }}>
            <SpinnerLoader />
          </div>
        ) : (
          <div>
            {this.state.totalItems > 0 && (
              <div>
                <hr />
                <div className="tableControls">
                  <p style={{ textAlign: "right" }}>
                    <FormattedMessage id="vl-results" /> :{" "}
                    {this.state.totalItems}
                  </p>
                </div>
                <DynamicTable data={this.state.tableData} />
              </div>
            )}
          </div>
        )}
      </Container>
    );
  }
}

export default ViewTypePage;
