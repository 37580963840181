/**
 * Created by Pedro Ortiz
 * Feb 2021
 * This is based on Genial Web project.
 * Several changes were made to fit the original programmer style of this project.
 */
import React from 'react';
import Select from 'react-select';
/** Multiple Language imports **/
import {BeatLoader} from "react-spinners";
import SessionManager from "../../utils/authHandling/session-manager";
import axios from "../../server/interceptors";
import {apiGetUserRoles, DEFAULT_LANGUAGE} from "../../config";
import {logoutUser} from "../../utils/authHandling/logout-user";
/** End of Multiple Language imports **/

/**
 * Handles the selection of the roles
 */
class SelectorRoles extends React.Component{

    constructor(props)
    {
        // Super is always called when defining the constructor of a subclass
        // All React component classes that have a constructor, should start it with a super(props) call.
        super(props);
        this.state = {
            loading: false,
            options: [], // The options to fill the selection
            value: typeof props.roleId !== 'undefined' && typeof props.roleId != null ? props.roleId : null,
        };
    }

    /**
     * Call the data API
     */
    callData(){
        this.setState({
            loading: true,
        });
        let self = this;
        axios.get(apiGetUserRoles, {
            headers: {
                'Content-Type': 'application/json',
            }}).then(function(response){

            if(response.status === 401)
            {
                logoutUser();
            }
            let arrOptions = [];
            if(response.data !== null) {
                for(let i=0; i < response.data.length;i++) {
                    arrOptions.push({
                        value: response.data[i].Id,
                        label: response.data[i].Name,
                    });
                }
            }
            self.setState({
                options: arrOptions,
                loading: false,
            });
        }).catch(error => {
            alert(error);
        });
    }

    /**
     * Call the webservices for the subscription and fill those selects
     * Component did mount is required for selects with API calls.
     */
    componentDidMount()
    {
        this.callData();
    }

    /**
     * Handles the change event to get the selected value. Do not change this function
     * @param event The event (on change)
     */
    handleChange = (object) => {
        this.setState({value: object.value}, ()=>{
            this.props.updateParent(this.state.value);
            return this.state.value;
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            value: nextProps.roleId,
        });

        if(typeof nextProps.redoSearch !== 'undefined' && nextProps.redoSearch === true)
        {
            this.callData()
        }
    }

    /**
     * Renders the item list
     * @returns {XML} Returns an XML of the selection
     */
    render(){
        if(this.state.loading)
        {
            return(<BeatLoader
                size={10}
                color={"#6bc4ff"}
            />)
        }

        return (
            <Select
                value={this.state.options.filter(option => option.value === this.state.value)}
                isDisabled={this.state.disabled} options={this.state.options} name="roles"
                onChange={this.handleChange} placeholder=""
            />
        )
    }
}

export default SelectorRoles;