import axios from "../interceptors";
import { apiLessonStatusesUrl } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const onStatusCall = function () {
  return axios
    .get(apiLessonStatusesUrl, {
      headers: {
        "Content-Type": "application/json",        
      },
    })
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        return response.data.StatusOfLessons.map((item) => ({
          title: item.Name,
          code: item.Id,
        }));
      } else if (response.status === 401) {
        //session is not valid, user is sent to login
        alert("Session is not valid.");
        logoutUser();
      }
    })
    .catch((error) => {
      console.log(error.response.data.Messages);
    });
};
