import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import "./view-unit-page.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FaSearch, FaBook } from "react-icons/fa";
import Select from "react-select";
import { onGradeCall } from "../../server/getLessonGradesCall/lesson-grade-call";
import { onSubjectCall } from "../../server/getLessonSubjectCall/lesson-subject-call";
import { getUnitsByGradeAndSubject } from "../../server/unitsCall/units-call";
import Table from "react-bootstrap/Table";
import SpinnerLoader from "../../components/spinnerLoader/spinner-loader";
import { Link } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ViewUnitPage = () => {
  const history = useHistory();
  let query = useQuery();
  let level = query.get("level");
  let subject = query.get("subject");
  const [grades, setGrades] = useState([]);
  const [gradeSelected, setGradeSelected] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState(null);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getGrades();
    getSubjects();
    if (level && subject) {
      getUnits(level, subject);
    }
  }, []);

  const getUnits = async (level, subject) => {
    setLoading(true);
    try {
      const res = await getUnitsByGradeAndSubject(level, subject);
      setUnits(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getGrades = async () => {
    try {
      const data = await onGradeCall(undefined);
      const grades = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      if (level && subject) {
        const grade = grades.find((item) => item.value === level);
        setGradeSelected(grade);
      }
      setGrades(grades);
    } catch (error) {}
  };

  const getSubjects = async () => {
    try {
      const data = await onSubjectCall();
      const subjects = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      if (level && subject) {
        const matter = subjects.find((item) => item.value === subject);
        setSubjectSelected(matter);
      }

      setSubjects(subjects);
    } catch (error) {}
  };

  const handleSearch = () => {
    if (validate()) {
      getUnits(gradeSelected.value, subjectSelected.value);
    }
  };

  const validate = () => {
    const errors = {};
    let formValid = true;

    if (!gradeSelected) {
      formValid = false;
      errors.gradeSelected = "Debe seleccinar el nivel";
    }

    if (!subjectSelected) {
      formValid = false;
      errors.subjectSelected = "Debe seleccinar la materia";
    }
    setErrors(errors);
    return formValid;
  };

  return (
    <Container fluid className="card-alt">
      <div className="wrapper-view-unit">
        <h3 className="mb-3">
          <FaBook/><FormattedMessage id="unit-view" />
        </h3>
        <div className="wrapper-selects">
          <div className="content-select">
            <div className="wrapper-content-select">
              <Select
                value={gradeSelected}
                onChange={(selected) => setGradeSelected(selected)}
                options={grades}
                placeholder="Seleccione..."
              />
              {errors.gradeSelected && (
                <span className="text-danger">
                  <FormattedMessage id="unit-required-select-level" />
                </span>
              )}
            </div>

            <div className="wrapper-content-select">
              <Select
                value={subjectSelected}
                onChange={(selected) => setSubjectSelected(selected)}
                options={subjects}
                placeholder="Seleccione..."
              />
              {errors.subjectSelected && (
                <span className="text-danger">
                  <FormattedMessage id="unit-required-select-subject" />
                </span>
              )}
            </div>
          </div>
          <div className="content-button-search">
            <Button variant="primary" type="submit" onClick={handleSearch}>
              {" "}
              <FaSearch />
              <FormattedMessage id="unit-button-search" />
            </Button>
          </div>
        </div>

        {loading ? (
          <div style={{ marginTop: "40px" }}>
            <SpinnerLoader />
          </div>
        ) : (
          <div className="wrapper-table">
            {units.length > 0 && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>
                      <FormattedMessage id="unit-table-colum-name" />
                    </th>
                    <th>
                      <FormattedMessage id="unit-table-colum-desc" />
                    </th>
                    <th>
                      <FormattedMessage id="unit-table-colum-duration" />
                    </th>
                    <th>
                      <FormattedMessage id="unit-table-colum-subject" />
                    </th>
                    <th>
                      <FormattedMessage id="unit-table-colum-level" />
                    </th>
                    <th>
                      <FormattedMessage id="unit-table-colum-date" />
                    </th>
                    <th>
                      <FormattedMessage id="unit-table-colum-options" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {units.map((item, i) => (
                    <tr key={i}>
                      <td>{item.Id}</td>
                      <td>{item.Name}</td>
                      <td>{item.Desc}</td>
                      <td>{item.Duration}</td>
                      <td>{item.SubjectCode}</td>
                      <td>{item.LevelCode}</td>
                      <td>{item.CreatedAt}</td>
                      <td>
                        <Link to={`/create-units?id=${item.Id}`}>
                          <FormattedMessage id="unit-table-colum-opt-edit" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default ViewUnitPage;
