import React, { Component } from 'react';

import { Player } from 'video-react';

class VideoPlayer extends Component{
  componentDidMount(){

  }
  render(){
    return(
        <Player OnContextMenu={e => e.preventDefault()}
          src={this.props.videoSrc}
        />
    );
  }
}

export default VideoPlayer;