import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'react-select';

class TypeSelector extends Component{
  constructor(props){
    super(props);
    this.state={selectedType: ""};
    this.selectType = this.selectType.bind(this);
  }

  selectType(typeObject){
    this.setState({selectedType: typeObject});
    this.props.changeParentState(this.props.stateName, typeObject.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.content === "EXA"){
      this.setState({
        selectedType: {
          label: "Examples",
          value: "EXA"
        }
      })
    } else if(nextProps.content === "DEF"){
      this.setState({
        selectType: {
          label: "Definitions",
          value: "DEF"
        }
      })
    }
  }

  render(){
    const { intl } = this.props;
    const options = [
      {
        label: intl.formatMessage({id: "ct-definitions"}),
        value: "DEF"
      },
      {
        label: intl.formatMessage({id: "ct-examples"}),
        value: "EXA"
      }
    ]
    return(
      <div>
        <div className='FixLabel'>{this.props.require === "1" ?  <span style={{color: "#f00"}}>*</span> : ""}<FormattedMessage id={this.props.label}/></div>
        <div>
        <Select 
          value={this.state.selectedType}
          onChange={this.selectType}
          options={options}
        />
        </div>
      </div>
    );
  }
}

export default injectIntl(TypeSelector);