import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import DropdownSelector from '../dropdownSelector';
import { onGradeCall } from '../../server/getLessonGradesCall/lesson-grade-call';
import SpinnerLoader from '../spinnerLoader';
import {Col, Row} from "react-bootstrap";

class RadioButtonGrades extends Component{
  constructor(props){
    super(props);
    this.state={
      loading: true,
      selectedGrade: this.props.content,
      gradesFromServer: []
    };
    this.getTitle = this.getTitle.bind(this);
  }

  getTitle(code){
    let title;
    this.state.gradesFromServer.forEach((item, index) => {
      if(item.code === code){
        title = item.title;
      }
    });
    return title;
  }

  componentDidMount(){
    onGradeCall(this.props.language).then((data) => {
      this.setState({
        gradesFromServer: data,
        loading: false
      });
    })
  }

  render(){
    if(this.state.loading){
      return (
        <div>
          <SpinnerLoader/>
        </div>
      );
    } else {
      return(
          <Row>
            <Col xs={3} md={2}>
              <b><FormattedMessage id="cl-select-grade"/></b>
            </Col>
          {this.props.isValidated ? <span className="text-danger"><FormattedMessage id="va-lesson-radios"/></span> : ""}
          {/* <DynamicRadioChecklist 
            items={this.state.gradesFromServer}
            parentState={this.state.selectedGrade}
            stateName={this.props.textName}
            onChange={this.props.onChange}
            content={this.props.content}
          /> */}
            <Col xs={9} md={10}>
              <DropdownSelector
                content={this.state.gradesFromServer}
                stateName={this.props.textName}
                onChange={this.props.onChange}
                buttonTitle={this.props.content ? this.getTitle(this.props.content) : <FormattedMessage id="cl-select-grade"/>}
                noContentMessage="Debe seleccionar un grado"
              />
            </Col>
          </Row>
      );
    }
  }
}

export default RadioButtonGrades;