import React, { Component } from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import RichTextInput from '../../../../../../components/richTextInput';
import QuestionAnswersList from '../questionAnswerList';
import QuizRubricComponent from '../quizRubricComponent';
import {FaTrash} from 'react-icons/fa'
import SpinnerLoader from "../../../../../../components/spinnerLoader";
class QuestionComponent extends Component{
    constructor(props){
        super(props);
        this.state={
            fileRules: this.props.fileRules
        }
    }
  render(){
    const { question, deleteQuestion } = this.props;
    console.log("QuestionComponent fileRules UPDATED?", this.state.fileRules);

    if(typeof this.state.fileRules === 'undefined' || this.state.fileRules === null ||  this.state.fileRules === [])
    {
        return <SpinnerLoader/>
    }

    return(
      <div>
        {/* Question */}
        <Row>
            <Col xs={8}>
                <h3><FormattedMessage id="qp-question"/> {this.props.question.index + 1}</h3>
            </Col>
            <Col xs={4} style={{textAlign: 'right'}}>
                <Button
                    variant="danger"
                    onClick={() => deleteQuestion(question.index)}
                ><FaTrash/>{' '}<FormattedMessage id="qp-delete-question"/></Button>
            </Col>
        </Row>

        <RichTextInput
            size={230}
          subject={this.props.lessonSubject}
          grade={this.props.lessonGrade} 
          onChange={this.props.onChangeQuestion} 
          content={this.props.quizQuestionModel? this.props.quizQuestionModel : ""}
          limitFromRules={this.props.quizRules ? this.props.quizRules.QuestionMaxLength : null}
          fileRules={this.state.fileRules}
        />
        <hr/>
        {/* Response */}
        <h4><FormattedMessage id="qp-response"/></h4>
          <hr/>
        <QuestionAnswersList 
          addNewAnswer={this.props.addNewAnswer} 
          onChangeAnswer={this.props.onChangeAnswer}
          onChangeCorrectStatus={this.props.onChangeCorrectAnswerStatus}
          responses={this.props.quizQuestionResponseModelList}
          deleteAnswer={this.props.deleteAnswer}
          lessonSubject={this.props.lessonSubject}
          lessonGrade={this.props.lessonGrade}
          quizRules={this.props.quizRules}
          fileRules={this.state.fileRules}
        />
        {/* Rubric */}
        
        {this.props.quizLevel >= 3 ?
          <div>
            <QuizRubricComponent
              content={this.props.rubricContent}
              onChange={this.props.onChangeRubricContent}
              fileRules={this.state.fileRules}
            />  
          </div>
        : ""}

      </div>
    );
  }
}

export default QuestionComponent;