import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faVolumeUp, faFileAudio, faVideo, faQuestion } from '@fortawesome/free-solid-svg-icons';

class CircleItem extends Component{
  render(){
    const {imgSrc} = this.props;
    let icon;
    switch(imgSrc){
      case "faImage":
        icon = faImage;
        break;
      case "audio":
        icon = faVolumeUp;
        break;
      case "video":
        icon = faVideo;
        break;
      case "introAudio":
        icon = faFileAudio;
        break;
      default: 
        icon = faQuestion;
        break;
    }

    const { size } = this.props;

    return(
      <div> 
        <div className="itemContainer">
          <div style={{width: size, 
            height: size,
            borderRadius: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#54a452"
            }}>
            <FontAwesomeIcon icon={icon} size="2x" color="#dddddd"/>
          </div>
        <p style={{fontSize: "1.1em", 
          textAlign: "center", 
          fontWeight: "500" }}>{this.props.children}</p>
        </div> 
      </div>

    );
  }
}

export default CircleItem;