import React, { Component } from 'react';
import axios from "../../server/interceptors";
import {
    apiGetStandard,
    apiGetExpectations,
    apiGetSubCodes,
    apiGetSubMainSubCodes,
    DEFAULT_LANGUAGE
} from '../../config';

import DropdownSelector from './subComponents/dropdownSelector/dropdown-selector';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from "react-bootstrap";
import SessionManager from "../../utils/authHandling/session-manager";
class StandardCodeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstLoad: true,
            standardCodesFromServer: [],
            expectationsFromServer: [],
            subcodesFromServer: [],
            subMainSubCodesFromServer: [],
            selectedStandard: "",
            selectedExpectation: "",
            selectedSubcode: "",
            selectedSubMainSubCode: "",
            subjectFromProps: "",
            gradeFromProps: "",
            standardFromProps: "",
            expectativeFromProps: "",
            subcodeFromProps: "",
            subMainSubCodeFromProps: "",
            subjectCode: "",
            showExpectation: typeof props.showExpectation === 'undefined' ? true : props.showExpectation,
            showIndicator: typeof props.showIndicator === 'undefined' ? true : props.showIndicator,
            showSubIndicator: typeof props.showSubIndicator === 'undefined' ? true : props.showSubIndicator,
        };

        this.getStandards = this.getStandards.bind(this);
        this.getExpectations = this.getExpectations.bind(this);
        this.getSubCodes = this.getSubCodes.bind(this);
        this.getSubMainSubCodes = this.getSubMainSubCodes.bind(this);
        this.onSelectStandard = this.onSelectStandard.bind(this);
        this.onSelectExpectation = this.onSelectExpectation.bind(this);
        this.onSelectSubcode = this.onSelectSubcode.bind(this);
        this.onSelectSubmainSubcode = this.onSelectSubmainSubcode.bind(this);
        this.loadStandardFromProps = this.loadStandardFromProps.bind(this);
        this.loadExpectationFromProps = this.loadExpectationFromProps.bind(this);
        this.loadSubCodesFromProps = this.loadSubCodesFromProps.bind(this);
        this.loadSubMainSubCodesFromProps = this.loadSubMainSubCodesFromProps.bind(this);
    }

    getStandards() {
        let self = this;
        if (typeof this.props.subjectCode !== 'undefined') {
            axios(apiGetStandard + this.props.subjectCode, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let standards = [];

                    response.data.map((item) => {
                        return standards.push({ id: item.StandardModel.Id, code: item.StandardModel.Code, description: item.StandardModel.Name });
                    });
                    self.setState({ standardCodesFromServer: standards });
                }
            });
        }
    }

    getExpectations() {
        if (this.state.showExpectation) {
            let self = this;
            let url = typeof this.props.grade !== 'undefined' ? apiGetExpectations + "/" + this.state.selectedStandard.id + "/" + this.props.grade : apiGetExpectations + "/" + this.state.selectedStandard.id;

            axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let expectations = [];
                    response.data.map((item) => {
                        return expectations.push({
                            id: item.CodeMainModel.Id,
                            code: item.CodeMainModel.Code,
                            description: item.CodeMainModel.Desc
                        });
                    });
                    self.setState({ expectationsFromServer: expectations });
                }
            });
        }
    }

    getSubCodes() {

        if (this.state.showIndicator) {
            let self = this;
            axios.get(apiGetSubCodes + "/" + this.state.selectedExpectation.id, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let subcodes = [];
                    response.data.map((item) => {
                        return subcodes.push({
                            id: item.CodeSubmainModel.Id,
                            code: item.CodeSubmainModel.Code,
                            description: item.CodeSubmainModel.Desc
                        });
                    });
                    self.setState({ subcodesFromServer: subcodes });
                }
            })
        }
    }

    getSubMainSubCodes() {
        if (this.state.showSubIndicator) {
            let self = this;
            axios.get(apiGetSubMainSubCodes + "/" + this.state.selectedSubcode.id, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let submainSubcodes = [];
                    response.data.map((item) => {
                        return submainSubcodes.push({
                            id: item.SubmainSubCodeModel.Id,
                            code: item.SubmainSubCodeModel.Code,
                            description: item.SubmainSubCodeModel.Desc
                        });
                    });
                    self.setState({ subMainSubCodesFromServer: submainSubcodes });
                }
            })
        }
    }

    onSelectStandard(value) {
        this.setState({
            selectedStandard: value,
            selectedExpectation: "",
            selectedSubcode: "",
            selectedSubMainSubCode: "",
            expectationsFromServer: [],
            subcodesFromServer: []
        }, () => {
            this.getExpectations();
            this.props.onChange("selectedStandardId", value.id);
            this.props.onChange("lessonInternalCode", { 'standard': value.code });
            this.props.onChange("selectedExpectationId", "");
            this.props.onChange("selectedSubCodeId", "");
            this.props.onChange("selectedSubMainSubCodeId", "");
        });
    }
    onSelectExpectation(value) {
        this.setState({
            selectedExpectation: value,
            selectedSubcode: "",
            selectedSubMainSubCode: "",
            subcodesFromServer: []
        }, () => {
            this.getSubCodes();
            this.props.onChange("selectedExpectationId", value.id);
            this.props.onChange("lessonInternalCode", { ...this.props.lessonInternalCode, 'expectation': value.code, 'subCode': '', 'subMainSubCode': '' });
            this.props.onChange("selectedSubCodeId", "");
            this.props.onChange("selectedSubMainSubCodeId", "");
        });
    }
    onSelectSubcode(value) {
        this.setState({
            selectedSubcode: value,
            selectedSubMainSubCode: ""
        }, () => {
            this.getSubMainSubCodes()
            this.props.onChange("selectedSubCodeId", value.id);
            this.props.onChange("lessonInternalCode", { ...this.props.lessonInternalCode, 'subCode': value.code, 'subMainSubCode': '' });
            this.props.onChange("selectedSubMainSubCodeId", "");
        });
    }
    onSelectSubmainSubcode(value) {
        this.setState({
            selectedSubMainSubCode: value
        }, () => {
            this.props.onChange("selectedSubMainSubCodeId", value.id);
            this.props.onChange("lessonInternalCode", { ...this.props.lessonInternalCode, 'subMainSubCode': value.code });
        })
    }

    loadStandardFromProps(id) {
        //Use id to get the standard object
        let self = this;

        if (typeof this.state.subjectFromProps !== 'undefined') {
            axios(apiGetStandard + this.state.subjectFromProps, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let standards = [];
                    response.data.map((item) => {
                        return standards.push({ id: item.StandardModel.Id, code: item.StandardModel.Code, description: item.StandardModel.Name });
                    });
                    self.setState({ standardCodesFromServer: standards }, function () {
                        self.state.standardCodesFromServer.forEach(function (item) {
                            if (item.id === id) {
                                self.setState({
                                    selectedStandard: item
                                }, function () {
                                    if (this.state.expectativeFromProps && this.state.selectedExpectation === "") {
                                        this.loadExpectationFromProps(this.state.expectativeFromProps, this.state.gradeFromProps);
                                    } else {
                                        self.setState({ firstLoad: false });
                                    }
                                });
                            }
                        });
                    });
                }
            });
        }
    }

    loadExpectationFromProps(id, grade) {
        if (this.state.showExpectation) {
            let self = this;
            let url = grade !== 'undefined' && grade !== null ? apiGetExpectations + "/" + this.state.selectedStandard.id + "/" + grade : apiGetExpectations + "/" + this.state.selectedStandard.id;
            axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let expectations = [];
                    response.data.map((item) => {
                        return expectations.push({
                            id: item.CodeMainModel.Id,
                            code: item.CodeMainModel.Code,
                            description: item.CodeMainModel.Desc
                        });
                    });
                    self.setState({ expectationsFromServer: expectations }, function () {
                        self.state.expectationsFromServer.forEach(function (item) {
                            if (item.id === id) {
                                self.setState({
                                    selectedExpectation: item,
                                }, function () {
                                    if (this.state.subcodeFromProps && this.state.selectedSubcode === "") {
                                        this.loadSubCodesFromProps(this.state.subcodeFromProps);
                                    } else {
                                        self.setState({ firstLoad: false });
                                    }
                                });
                            }
                        });
                    });
                }
            });
        }
    }

    loadSubCodesFromProps(id) {
        if (this.state.showIndicator) {
            let self = this;

            axios.get(apiGetSubCodes + "/" + this.state.selectedExpectation.id, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let subcodes = [];
                    response.data.map((item) => {
                        return subcodes.push({
                            id: item.CodeSubmainModel.Id,
                            code: item.CodeSubmainModel.Code,
                            description: item.CodeSubmainModel.Desc
                        });
                    });

                    self.setState({ subcodesFromServer: subcodes }, function () {
                        self.state.subcodesFromServer.forEach(function (item) {
                            if (item.id === id) {
                                self.setState({
                                    selectedSubcode: item
                                }, function () {
                                    if (this.state.subMainSubCodeFromProps && this.state.selectedSubMainSubCode === "") {
                                        this.loadSubMainSubCodesFromProps(this.state.subMainSubCodeFromProps);
                                    } else {
                                        self.setState({ firstLoad: false });
                                    }
                                });
                            }
                        })
                    });
                }
            })
        }
    }

    loadSubMainSubCodesFromProps(id) {

        if (this.state.showSubIndicator) {
            let self = this;
            axios.get(apiGetSubMainSubCodes + "/" + this.state.selectedSubcode.id, {
                headers: {
                    'Content-Type': 'application/json',                    
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    let submainSubcodes = [];
                    response.data.map((item) => {
                        return submainSubcodes.push({ id: item.SubmainSubCodeModel.Id, code: item.SubmainSubCodeModel.Code, description: item.SubmainSubCodeModel.Desc });
                    });
                    self.setState({ subMainSubCodesFromServer: submainSubcodes }, function () {
                        self.state.subMainSubCodesFromServer.forEach(function (item) {
                            if (item.id === id) {
                                self.setState({
                                    selectedSubMainSubCode: item,
                                    firstLoad: false
                                });
                            }
                        })
                    });
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {

        // here is where the props will arrive

        if (this.props.loading) {
            if (nextProps.selectedStandardId && this.state.selectedStandard === "") {
                this.setState({
                    standardFromProps: nextProps.selectedStandardId,
                    expectativeFromProps: nextProps.selectedExpectationId,
                    subcodeFromProps: nextProps.selectedSubCodeId,
                    subMainSubCodeFromProps: nextProps.selectedSubMainSubCodeId,
                    subjectFromProps: nextProps.subjectCode,
                    gradeFromProps: nextProps.grade,
                    showExpectation: typeof nextProps.showExpectation === 'undefined' ? true : nextProps.showExpectation,
                    showIndicator: typeof nextProps.showIndicator === 'undefined' ? true : nextProps.showIndicator,
                    showSubIndicator: typeof nextProps.showSubIndicator === 'undefined' ? true : nextProps.showSubIndicator,
                }, function () {
                    this.loadStandardFromProps(this.state.standardFromProps);
                });
            } else {
                this.setState({ firstLoad: false })
            }
        } else {
            if (nextProps.subjectCode !== this.state.subjectFromProps || nextProps.grade !== this.state.gradeFromProps) {
                this.setState({
                    subjectFromProps: nextProps.subjectCode,
                    gradeFromProps: nextProps.grade,
                    selectedStandard: "",
                    selectedExpectation: "",
                    selectedSubcode: "",
                    selectedSubMainSubCode: "",
                    showExpectation: typeof nextProps.showExpectation === 'undefined' ? true : nextProps.showExpectation,
                    showIndicator: typeof nextProps.showIndicator === 'undefined' ? true : nextProps.showIndicator,
                    showSubIndicator: typeof nextProps.showSubIndicator === 'undefined' ? true : nextProps.showSubIndicator,
                }, function () {
                    this.getStandards();
                    this.props.onChange("selectedStandardId", "");
                    this.props.onChange("selectedExpectationId", "");
                    this.props.onChange("selectedSubCodeId", "");
                    this.props.onChange("selectedSubMainSubCodeId", "");
                });
            }
        }
    }

    render() {
        const {
            selectedStandard,
            selectedExpectation,
            selectedSubcode,
            selectedSubMainSubCode,
            standardCodesFromServer,
            expectationsFromServer,
            subcodesFromServer,
            subMainSubCodesFromServer
        } = this.state
        return (
            <Row>
                <Col xs={3} md={2}>
                    <b><FormattedMessage id="cl-code-input" /></b>
                    {/* <StandardCodeDisplay 
          standard={selectedStandard ? selectedStandard.code : ""} 
          expectation={selectedExpectation ? selectedExpectation.code : ""} 
          subcode={selectedSubcode ? selectedSubcode.code : ""}
          submainSubCode={selectedSubMainSubCode ? selectedSubMainSubCode : ""}
        /> */}
                </Col>
                <Col xs={9} md={10}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <DropdownSelector
                            content={standardCodesFromServer}
                            buttonTitle={selectedStandard ? selectedStandard.code : <FormattedMessage id="cl-standard" />}
                            onChange={this.onSelectStandard}
                            noContentMessage="Debe seleccionar una materia"
                        />
                        {this.state.showExpectation ?
                            <DropdownSelector
                                content={expectationsFromServer}
                                buttonTitle={selectedExpectation ? selectedExpectation.code : <FormattedMessage id="cl-expectation" />}
                                onChange={this.onSelectExpectation}
                                noContentMessage="Debe seleccionar un standard"
                            /> : null
                        }
                        {this.state.showIndicator ?
                            <DropdownSelector
                                content={subcodesFromServer}
                                buttonTitle={selectedSubcode ? selectedSubcode.code : <FormattedMessage id="cl-indicator" />}
                                onChange={this.onSelectSubcode}
                                noContentMessage="Debe seleccionar una expectativa"
                            /> : null}
                        {this.state.showSubIndicator ?
                            <DropdownSelector
                                content={subMainSubCodesFromServer}
                                buttonTitle={selectedSubMainSubCode ? selectedSubMainSubCode.code : <FormattedMessage id="cl-subindicator" />}
                                onChange={this.onSelectSubmainSubcode}
                                noContentMessage="Debe seleccionar un subcodigo"
                            /> : null
                        }
                    </div>
                </Col>
            </Row>
        );
    }
}

export default StandardCodeSelector;