import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./view-unit-page.css";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";
import { onGradeCall } from "../../server/getLessonGradesCall/lesson-grade-call";
import { onSubjectCall } from "../../server/getLessonSubjectCall/lesson-subject-call";
import Table from "react-bootstrap/Table";
import SpinnerLoader from "../../components/spinnerLoader/spinner-loader";
import TextInput from "../../components/textInput";
import {
  getCodesTypes,
  getCodesByQuery,
} from "../../server/getCodeTypesCall/code-type-call";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import Pagination from "@material-ui/lab/Pagination";

const ModalConstructorCode = ({
  handleUpdateCode,
  currentItemCode,
  handleClose,
  showInputCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [grades, setGrades] = useState([]);
  const [gradeSelected, setGradeSelected] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState(null);
  const [codetypes, setCodeTypes] = useState([]);
  const [codeTypeSelected, setCodeTypeSelected] = useState(null);
  const [constructedCode, setConstructedCode] = useState("");
  const [rowConstructedCode, setRowConstructedCode] = useState([]);
  const [codes, setCodes] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [relationCode, setRelationCode] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    getGrades();
    getSubjects();
    getCodeTypes();
    if (currentItemCode && currentItemCode.ConstructedCodeCodeModels.length) {
      setRowConstructedCode(currentItemCode.ConstructedCodeCodeModels);
      setConstructedCode(
        currentItemCode.ConstructedCodeCodeModels.map(
          (x) => x.Code + (x.DotAfter === "1" ? "." : "")
        ).join("")
      );
    }
  }, []);

  useEffect(() => {
    handleUpdateCode(rowConstructedCode);
  }, [constructedCode]);

  const handleSearch = () => {
    if (validate()) {
      const page = 1;
      setPage(page);
      getCodes(
        gradeSelected ? gradeSelected.value : undefined,
        subjectSelected ? subjectSelected.value : undefined,
        codeTypeSelected ? codeTypeSelected.value : undefined,
        searchText,
        page,
        pageSize
      );
    }
  };

  const getCodes = async (level, subject, type, codeLike, page, pageSize) => {
    page = page - 1;
    setLoading(true);
    try {
      const data = {
        TypeId: type && Number(type),
        CodeLevelModels: [{ LevelCode: level }],
        CodeSubjectModels: [{ SubjectCode: subject }],
        Description: codeLike,
      };
      const codes = await getCodesByQuery(data, page, pageSize);
      setCodes(codes.Items);
      setCount(codes.TotalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCodeTypes = async () => {
    try {
      const data = await getCodesTypes();
      const codeTypes = data.map((item) => ({
        value: item.Id,
        label: item.Name,
      }));
      setCodeTypes(codeTypes);
    } catch (error) {
      alert(error);
    }
  };

  const getGrades = async () => {
    try {
      const data = await onGradeCall(undefined);
      const grades = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      setGrades(grades);
    } catch (error) {}
  };

  const getSubjects = async () => {
    try {
      const data = await onSubjectCall();
      const subjects = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      setSubjects(subjects);
    } catch (error) {}
  };

  const selectCode = (item) => {
    rowConstructedCode.push({
      Code: item.Code,
      CodeId: item.Id,
      DotAfter: "0",
    });
    setRowConstructedCode(rowConstructedCode);
    setConstructedCode(
      rowConstructedCode
        .map((x) => x.Code + (x.DotAfter === "1" ? "." : ""))
        .join("")
    );
  };

  const addDot = () => {
    if (rowConstructedCode.length > 0) {
      let codes = rowConstructedCode;
      let cc = rowConstructedCode[rowConstructedCode.length - 1];
      cc.DotAfter = "1";
      codes.splice(rowConstructedCode.length - 1, 1, cc);
      setRowConstructedCode(codes);
      setConstructedCode(
        rowConstructedCode
          .map((x) => x.Code + (x.DotAfter === "1" ? "." : ""))
          .join("")
      );
      handleUpdateCode(rowConstructedCode);
    }
  };

  const goBackInputCode = () => {
    const codes = [...rowConstructedCode];
    codes.pop();
    setRowConstructedCode(codes);
    setConstructedCode(
      codes.map((x) => x.Code + (x.DotAfter === "1" ? "." : "")).join("")
    );
    handleUpdateCode(codes);
  };

  const validate = () => {
    const errors = {};
    let formValid = true;

    if (!gradeSelected) {
      formValid = false;
      errors.gradeSelected = "Debe seleccinar el nivel";
    }

    if (!subjectSelected) {
      formValid = false;
      errors.subjectSelected = "Debe seleccinar la materia";
    }

    if (!codeTypeSelected) {
      formValid = false;
      errors.typeSelected = "Debe seleccinar el elemento de código";
    }
    setErrors(errors);
    return formValid;
  };

  const onChangeText = (name, value) => {
    setSearchText(value);
  };

  const onChangeRelacionCode = (e) => {
    const checked = e.target.checked;
    setRelationCode(checked);
  };

  const handleChangePaginate = (event, value) => {
    setPage(value);
    getCodes(
      gradeSelected ? gradeSelected.value : undefined,
      subjectSelected ? subjectSelected.value : undefined,
      codeTypeSelected ? codeTypeSelected.value : undefined,
      searchText,
      value,
      pageSize
    );
  };

  return (
    <Container fluid className="card-alt" style={{ boxSizing: "border-box" }}>
      <div className="wrapper-view-unit">
        <Row>
          <Col>
            <h5>
              <FormattedMessage id="vl-search" />
            </h5>
            <small>
              <FormattedMessage id="codes-instructions" />
            </small>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3 col-w-full">
            <TextInput
              textName="search"
              content={searchText}
              onChange={onChangeText}
              placeholder={intl.formatMessage({
                id: "codes-search-by-id-code-or-description",
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Select
              isClearable={true}
              value={codeTypeSelected}
              onChange={(selected) => setCodeTypeSelected(selected)}
              options={codetypes}
              placeholder=<FormattedMessage id="codes-select-element" />
            />
            {errors.typeSelected && (
              <span className="text-danger">
                <FormattedMessage id="unit-required-select-type" />
              </span>
            )}
          </Col>
          <Col xs={4}>
            <Select
              isClearable={true}
              value={subjectSelected}
              onChange={(selected) => setSubjectSelected(selected)}
              options={subjects}
              placeholder=<FormattedMessage id="codes-subject" />
            />
            {errors.subjectSelected && (
              <span className="text-danger">
                <FormattedMessage id="unit-required-select-subject" />
              </span>
            )}
          </Col>
          <Col xs={4}>
            <Select
              isClearable={true}
              value={gradeSelected}
              onChange={(selected) => setGradeSelected(selected)}
              options={grades}
              placeholder=<FormattedMessage id="codes-level" />
            />
            {errors.gradeSelected && (
              <span className="text-danger">
                <FormattedMessage id="unit-required-select-level" />
              </span>
            )}
          </Col>
          <Col xs={12} className="mt-3 small">
            {rowConstructedCode.length > 0 && (
              <Form.Check
                checked={relationCode}
                type="checkbox"
                label={<FormattedMessage id="cl-relation-code" />}
                onClick={(e) => onChangeRelacionCode(e)}
              />
            )}
          </Col>
          <Col xs={3} className="mt-3">
            <Button variant="primary" type="submit" onClick={handleSearch}>
              {" "}
              <FaSearch />
              <FormattedMessage id="unit-button-search" />
            </Button>
          </Col>
        </Row>

        <hr />
        {showInputCode && (
          <Row className="Row-AlignCenter">
            <Col className="flex mt-2 Region-AddCode__ModalButton">
              <Button variant="light" onClick={addDot}>
                <FormattedMessage id="cl-add-point" />
              </Button>
              <Button variant="light" onClick={goBackInputCode}>
                <FontAwesomeIcon icon={faEraser} />
                <FormattedMessage id="cl-delete-code" />
              </Button>
            </Col>
            <Col xs={12}>
              {constructedCode ? (
                <TextInput
                  labelID="cl-code-input"
                  textName="lessonStandardCode"
                  readOnly={true}
                  content={constructedCode}
                />
              ) : (
                <TextInput
                  labelID="cl-code-input"
                  textName="lessonStandardCode"
                  readOnly={true}
                  content={""}
                />
              )}
            </Col>
            <Col className="flex mt-2 Region-AddCode__ModalButton">
              <Button variant="success" onClick={handleClose}>
                <FormattedMessage id="cl-add-code" />
              </Button>
            </Col>
          </Row>
        )}

        {loading ? (
          <div style={{ marginTop: "40px" }}>
            <SpinnerLoader />
          </div>
        ) : (
          <div className="wrapper-table">
            {codes.length > 0 ? (
              <>
                <Table
                  striped
                  bordered
                  hover
                  className="w-auto table-responsive"
                >
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="unit-table-colum-id" />
                      </th>
                      <th>
                        <FormattedMessage id="unit-table-colum-code" />
                      </th>
                      <th>
                        <FormattedMessage id="unit-table-colum-desc" />
                      </th>
                      <th>
                        <FormattedMessage id="unit-table-colum-type" />
                      </th>
                      <th>
                        <FormattedMessage id="unit-table-colum-subject" />
                      </th>
                      <th>
                        <FormattedMessage id="unit-table-colum-level" />
                      </th>
                      <th>
                        <FormattedMessage id="unit-table-colum-options" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {codes.map((item, i) => (
                      <tr key={i}>
                        <td>{item.Id}</td>
                        <td>{item.Code}</td>
                        <td>{item.Description}</td>
                        <td>{item.CodeTypeName}</td>
                        <td>
                          {item.CodeSubjectModels.length > 0
                            ? item.CodeSubjectModels[0].SubjectName
                            : ""}
                        </td>
                        <td>
                          {item.CodeLevelModels.length > 0
                            ? item.CodeLevelModels[0].LevelName
                            : ""}
                        </td>
                        <td>
                          <Button onClick={() => selectCode(item)}>
                            <FormattedMessage id="cl-select" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="pageControls">
                  {count > 1 && (
                    <Pagination
                      count={count}
                      page={page}
                      onChange={handleChangePaginate}
                    />
                  )}
                </div>
              </>
            ) : null}
            {/* {showGenericErrorNoCodeFound ? (
              <Alert variant="info">
                {" "}
                <small>
                  {" "}
                  <FormattedMessage id="codes-error-codes-not-found" />{" "}
                </small>
              </Alert>
            ) : null} */}
          </div>
        )}
      </div>
    </Container>
  );
};

export default ModalConstructorCode;
