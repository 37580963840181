import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import './view-lesson-pdf-page.css'
import LessonPdfDisplay from '../../components/lessonPdfDisplay/lesson-pdf-display-view.js';
import SessionManager from "../../utils/authHandling/session-manager";

class ViewLessonPdfPage extends Component {
    constructor(props){
        super(props)
        this.state = {
           roles: SessionManager.getSession() !== null ? SessionManager.getSessionDetails().Roles : [],
           lessonId: props.location.lessonId !== null ? props.location.lessonId : sessionStorage.getItem("lastSearch"),
           language: props.language
        }
        this.onChange = this.onChange.bind(this);
    }
    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        this.setState({[field]: value});
      };
    render(){
        return(
            <Container fluid className="card-alt">
                <Row>
          <Col xs={12}>
            <h3>
              <FontAwesomeIcon icon={faFilePdf} className="fontFilePdf"/>
              <FormattedMessage id="vp-title"/>{' '}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
          <LessonPdfDisplay lessonId={this.state.lessonId} language={this.state.language}/>
          </Col>
        </Row>
        </Container>
        )
    }
}

export default ViewLessonPdfPage;