import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SimpleFieldGenerator from '../../../simpleFieldGenerator';

class CreateLessonTareas extends Component{
  constructor(props){
    super(props);
    this.state={
      objectiveData: []
    }
    this.setObjectiveData = this.setObjectiveData.bind(this);
  }

  setObjectiveData(content){
    this.props.parentStateHandle("lessonTasks", content);
  }

  render(){
    return(
        <div className="card-alt-2">
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-richtext"/></p> : ""}
        <p><FormattedMessage id="cl-field-instruction"/></p>
        <SimpleFieldGenerator 
          titleId="af-tasks" 
          setParentState={this.setObjectiveData}
          state={this.props.state}
          />
      </div>
    );
  }
}

export default CreateLessonTareas;