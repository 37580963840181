import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import axios from "../../server/interceptors";
import { apiLessonCreate, DEFAULT_LANGUAGE } from "../../config";
import { onRulesCall } from "../../server/getFileUploadRulesCall/file-upload-rules-call";
import { getLessonRules } from "../../server/getLessonRulesCall/get-lesson-rules";
import { Button, Container } from "react-bootstrap";
import LessonDetailsDisplay from "../../components/lessonDetailsDisplay";
import { emptyLessonModel } from "./utils";
import SessionManager from "../../utils/authHandling/session-manager";
import PreSelectionPage from "../preSelectionPage";
import "./create-lesson-page-style.css";
import { withRouter } from "react-router-dom";

class CreateLessonPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      lessonAddress: "",
      lessonCode: "",
      lessonConcept: "",
      lessonDefinitions: [],
      lessonDescription: "",
      lessonExample: [],
      lessonGrade: "",
      lessonKnowledgeLevel: "",
      lessonNo: "",
      lessonObjectives: [],
      lessonStrategies: [],
      lessonSubject: "",
      lessonTasks: [],
      lessonHasRecorder: false,
      lessonTitle: "",
      lessonTransversalThemes: [],
      lessonUnit: [],
      lessonAudioSrc: "",
      lessonStandardCode: "",
      ConstructedCodes: [],
    };
    this.onCreateLessonCall = this.onCreateLessonCall.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onChange(field, value) {
    //Trick to add to state from multiple inputs with same function
    this.setState({ [field]: value });
  }

  onCreateLessonCall() {
    let params = emptyLessonModel;
    let proceedUpload = true;
    this.setState({
      validateLessonTitle: false,
      validateLessonNo: false,
      validateLessonDescription: false,
      validateLessonSubject: false,
      validateLessonGrade: false,
      validateLessonConcept: false,
    });
    if (this.state.lessonTitle === "") {
      proceedUpload = false;
      this.setState({ validateLessonTitle: true });
    }
    params.LessonModel.LessonTitle = this.state.lessonTitle;

    if (this.state.lessonNo === "") {
      //proceedUpload = false;
      this.setState({ lessonNo: "0", validateLessonNo: true });
    }
    params.LessonModel.LessonNo = this.state.lessonNo;

    if (this.state.lessonDescription === "") {
      proceedUpload = false;
      this.setState({ validateLessonDescription: true });
    }
    params.LessonDetailModel.Description = this.state.lessonDescription;
    if (this.state.lessonSubject === "") {
      proceedUpload = false;
      this.setState({ validateLessonSubject: true });
    }
    params.LessonModel.SubjectCode = this.state.lessonSubject;
    if (this.state.lessonGrade === "") {
      proceedUpload = false;
      this.setState({ validateLessonGrade: true });
    }
    params.LessonModel.LevelCode = this.state.lessonGrade;

    if (this.state.lessonConcept === "") {
      proceedUpload = false;
      this.setState({ validateLessonConcept: true });
    }
    params.LessonDetailModel.Concept = this.state.lessonConcept;

    /* Not Validations*/

    if (this.state.lessonAddress === "") {
      this.setState({ validateLessonAddress: true });
    }
    params.AddressToLessonModelList[0].AddressId = this.state.lessonAddress;
    if (this.state.lessonObjectives.length === 0) {
      this.setState({ validateLessonObjectives: true });
    }
    params.LessonObjectiveModelList = this.state.lessonObjectives;
    //Leesson tasks
    if (this.state.lessonTasks.length === 0) {
      this.setState({ validateLessonTasks: true });
    }
    params.LessonPerformanceTaskModelList = this.state.lessonTasks;
    //Lesson strategies
    if (this.state.lessonStrategies.length === 0) {
      this.setState({ validateLessonStrategies: true });
    }
    params.LessonStrategyModelList = this.state.lessonStrategies;
    //Lesson transversal themes
    if (this.state.lessonTransversalThemes.length === 0) {
      this.setState({ validateLessonTransversalThemes: true });
    }
    params.LessonTransversalThemeModelList = this.state.lessonTransversalThemes;
    //Lesson definitions
    if (this.state.lessonDefinitions.length === 0) {
      this.setState({ validateLessonDefinitions: true });
    }
    params.LessonDefinitionModelList = this.state.lessonDefinitions;
    //Lesson evxamples
    if (this.state.lessonExample.length === 0) {
      this.setState({ validateLessonExample: true });
    }
    params.LessonStandardCodeModelList[0].StandardCode =
      this.state.lessonStandardCode;
    params.LessonExampleModelList = this.state.lessonExample;
    //Lesson recorder
    params.LessonModel.HasAudioRecorder = this.state.lessonHasRecorder ? 1 : 0;
    //lesson audio model
    params.LessonIntroAudioModel = {
      AudioSrc: this.state.lessonIntroAudioSrc,
    };
    if (this.state.lessonUnit.length > 0) {
      let curricularMapItems = [];
      this.state.lessonUnit.forEach((item) => {
        curricularMapItems.push({ UnityId: item });
      });
      params.LessonCurricularMapModelList = curricularMapItems;
    }

    params.ConstructedCodeHandleModelList = this.state.ConstructedCodes;

    if (!proceedUpload) {
      return;
    }

    this.createLesson(params);
  }

  createLesson = async (params) => {
    const headers = {
      "Content-Type": "application/json",      
    };
    this.setState({ loading: true });
    try {
      const response = await axios.post(apiLessonCreate, params, { headers });
      alert(response.data.Messages);
      sessionStorage.setItem("lastSearch", response.data.LessonId);
      this.props.history.push("/lesson");
      this.setState({ loading: false });
    } catch (error) {
      console.log(error.response.data.Messages);
      this.setState({ loading: false });
    }
  };

  selectOption(value) {
    onRulesCall().then((data) => {
      this.setState({
        fileRules: data,
      });
    });
    getLessonRules(value).then((data) => {
      this.setState({
        lessonAddress: value,
        lessonAddressSelected: true,
        lessonRules: data,
      });
    });
  }

  getAddressDisplay() {
    switch (this.state.lessonAddress) {
      case "1":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>VR</b>
          </p>
        );
      case "2":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>Genial Web</b>
          </p>
        );
      case "3":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>PDF</b>
          </p>
        );
      case "4":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>AR</b>
          </p>
        );
      default:
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>Loading</b>
          </p>
        );
    }
  }

  constructedCodesChange = (constructedCodes) => {
    const data = constructedCodes.map((item) => {
      return {
        Id: item.Id,
        MainCode: item.MainCode,
        ConstructedCodeCodeModels: item.ConstructedCodeCodeModels,
      };
    });
    this.setState({ ConstructedCodes: data });
  };

  render() {
    return (
      <Container fluid className="card-alt clear-box-shadow">
        {this.state.lessonAddressSelected ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>
                <FormattedMessage id="cl-title" />
              </h3>
              <Button
                variant="success"
                disabled={this.state.loading}
                onClick={this.onCreateLessonCall}
              >
                <FormattedMessage id="cl-title" />
              </Button>
            </div>
            <div>{this.getAddressDisplay()}</div>
            <LessonDetailsDisplay
              onDataChange={this.constructedCodesChange}
              isCreation={true}
              language={this.props.language}
              lessonContent={this.state}
              onChange={this.onChange}
              internalCode={this.state.internalCode}
            />
          </div>
        ) : (
          <div>
            <PreSelectionPage
              title="cl-pre-creation"
              language={this.props.language}
              selectOption={this.selectOption}
            />
          </div>
        )}
      </Container>
    );
  }
}

export default withRouter(CreateLessonPage);
