import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import "./create-unit-page.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { onGradeCall } from "../../server/getLessonGradesCall/lesson-grade-call";
import { onSubjectCall } from "../../server/getLessonSubjectCall/lesson-subject-call";
import {
  createUnit,
  geUnitById,
  updateUnit,
} from "../../server/unitsCall/units-call";
import Alert from "react-bootstrap/Alert";
import { FaBook } from 'react-icons/fa';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateUnitPage = () => {
  const history = useHistory();
  let query = useQuery();
  let id = query.get("id");
  const [data, setData] = useState({
    name: "",
    description: "",
    duration: "",
  });
  const [grades, setGrades] = useState([]);
  const [gradeSelected, setGradeSelected] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrorMsg, setErroShow] = useState(false);

  useEffect(() => {
    if (id) {
      getUnit();
    } else {
      getGrades();
      getSubjects();
    }
  }, []);

  const getUnit = async () => {
    try {
      const res = await geUnitById(id);
      const dataGrades = await onGradeCall(undefined);
      const gradesMap = dataGrades.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      const grade = gradesMap.find((item) => item.value === res.data.LevelCode);
      const dataSubjects = await onSubjectCall();
      const subjectsMap = dataSubjects.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      const subject = subjectsMap.find(
        (item) => item.value === res.data.SubjectCode
      );
      setGradeSelected(grade);
      setSubjectSelected(subject);
      setData({
        name: res.data.Name,
        description: res.data.Desc,
        duration: res.data.Duration,
      });
    } catch (error) {}
  };

  const getGrades = async () => {
    try {
      const data = await onGradeCall(undefined);
      const grades = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      setGrades(grades);
    } catch (error) {}
  };

  const getSubjects = async () => {
    try {
      const data = await onSubjectCall();
      const subjects = data.map((item) => ({
        value: item.code,
        label: item.title,
      }));
      setSubjects(subjects);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (!validate()) {
      setErroShow(true);
      return;
    }
    if (id) {
      update();
    } else {
      create();
    }
  };

  const create = async () => {
    const req = {
      Name: data.name,
      Duration: data.duration,
      Desc: data.description,
      LevelCode: gradeSelected.value,
      SubjectCode: subjectSelected.value,
    };
    setLoading(true);
    try {
      await createUnit(req);
      setLoading(false);
      history.push(
        `/view-units?level=${gradeSelected.value}&subject=${subjectSelected.value}`
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const update = async () => {
    const req = {
      Id: id,
      Name: data.name,
      Duration: data.duration,
      Desc: data.description,
      LevelCode: gradeSelected.value,
      SubjectCode: subjectSelected.value,
    };
    setLoading(true);
    try {
      await updateUnit(req);
      setLoading(false);
      history.push(
        `/view-units?level=${gradeSelected.value}&subject=${subjectSelected.value}`
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const validate = () => {
    const errors = {};
    let formValid = true;

    if (!data.name) {
      formValid = false;
      errors.name = "The name field is required";
    }

    if (!data.description) {
      formValid = false;
      errors.description = "The description field is required";
    }

    if (!data.duration) {
      formValid = false;
      errors.duration = "The duration field is required";
    }

    if (!gradeSelected) {
      formValid = false;
      errors.gradeSelected = "The grade field is required";
    }

    if (!subjectSelected) {
      formValid = false;
      errors.subjectSelected = "The subject is required";
    }
    setErrors(errors);
    return formValid;
  };

  return (
    <>
      <Container fluid className="card-alt">
        <div className="conten-unit-page">
          <h3>
            <FaBook/><FormattedMessage id="unit-title-ppal" />
          </h3>
          {id ? (
            <Button variant="success" onClick={handleSubmit} disabled={loading}>
              <FormattedMessage id="unit-button-edit" />
            </Button>
          ) : (
            <Button variant="success" onClick={handleSubmit} disabled={loading}>
              <FormattedMessage id="unit-button-create" />
            </Button>
          )}
        </div>
        {showErrorMsg ? (
          <div style={{ marginTop: "2em" }}>
            <Row>
              <Col>
                <Alert
                  variant="danger"
                  onClose={() => setErroShow(false)}
                  dismissible
                >
                  <Alert.Heading>
                    <FormattedMessage id="error-verify-fields" />
                  </Alert.Heading>
                  <p>
                    <FormattedMessage id="error-verify-fields-user" />
                  </p>
                </Alert>
              </Col>
            </Row>
          </div>
        ) : null}
        <div className="card-alt-2 mt-4">
          <Form>
            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-name" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Form.Control
                    type="text"
                    onChange={handleInputChange}
                    name="name"
                    value={data.name}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-description" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Form.Control
                    type="text"
                    onChange={handleInputChange}
                    name="description"
                    value={data.description}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-duration" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Form.Control
                    type="number"
                    onChange={handleInputChange}
                    name="duration"
                    value={data.duration}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-level" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Select
                    value={gradeSelected}
                    onChange={(selected) => setGradeSelected(selected)}
                    options={grades}
                    placeholder="Seleccione..."
                    isDisabled={id}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col xs={3} md={2}>
                  <b>
                    <span>
                      <FormattedMessage id="unit-form-subject" />
                    </span>
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Select
                    value={subjectSelected}
                    onChange={(selected) => setSubjectSelected(selected)}
                    options={subjects}
                    placeholder="Seleccione..."
                    isDisabled={id}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CreateUnitPage;
