import React, { Component } from 'react';

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TextAreaInput extends Component{
  constructor(props){
    super(props);
    this.onTextChange = this.onTextChange.bind(this);
  }


  onTextChange(event){
    if(this.props.isForVR){
      this.props.onChange(this.props.parentStateKey, event.target.value);
    } else{
      this.props.onChange(event.target.value);
    }
  }


  render() {
    const { textType, textName } = this.props;
    return(
      <div>
        <Container>
          <Row>
            <Col>
              {this.props.label}
            </Col>
          </Row>  
          <Row>
            <Col lg="12">
              <Form.Control
                as={textType} 
                name={textName}
                onChange={this.onTextChange}
                value={this.props.content}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


export default TextAreaInput;