import React, { Component } from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import ReactRadioButtonGroup  from 'react-radio-button-group';
import './boolean-selector-style.css';

class BooleanSelector extends Component{

  constructor(props){
    super(props);
    this.changeOption = this.changeOption.bind(this);
  }

  changeOption(value){
    this.props.changeParentState(this.props.stateName, value);
  }

  render(){
    const { intl } = this.props;
    return(
      <div className='mt-4'>
        <p>{this.props.require === "1" ?  <span style={{color: "#f00"}}>*</span> : ""}<FormattedMessage id={this.props.label}/></p>
        <div className='FixRadioButton'>
          <ReactRadioButtonGroup 
            name={this.props.label} 
            options={[{value: "1", label: intl.formatMessage({id: "ct-yes"})}, {value: "0", label: intl.formatMessage({id: "ct-no"})}]} 
            value={this.props.content}
            groupClassName="booleanSelectorStyle"
            itemClassName="booleanSelectorItem"
            onChange={this.changeOption}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(BooleanSelector);