import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {apiStandardsCreate, DEFAULT_LANGUAGE} from '../../config';
import {Button, Container} from 'react-bootstrap';
import './create-standard-page-style.css';
import SessionManager from "../../utils/authHandling/session-manager";
import StandardDisplayView from "../../components/standardDisplayView";

class CreateStandardPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            id: "",
            code: "",
            name: "",
            year: "",
            languageSP: "",
            languageEN: "",
            selectedSubjects: [],
            redirect: false,
        };
        this.onCreateCall = this.onCreateCall.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value});
    }

    onCreateCall(){
        // Validate
        if(this.state.code === ""){
            alert("Error: The standard code is required. / El código estándar es requerido.");
            return;
        }
        if(this.state.name === ""){
            alert("Error: The name is required./El nombre es requerido.");
            return;
        }
        if(this.state.year === ""){
            alert("Error: The standard year is required./El año que fue publicado el estándar es requerido.");
            return;
        }
        if(this.state.selectedSubjects.length === 0){
            alert("Error: The subjects are required./Las materias son requeridas.");
            return;
        }
        if(this.state.languageSP.length === 0){
            alert("Error: The description in Spanish is required./La descripción en español es requerida.");
            return;
        }
        if(this.state.languageEN.length === 0){
            alert("Error: The description in English is required./La descripción en inglés es requerida.");
            return;
        }

        this.setState({loading: true}, ()=>{
            // Call the API to insert
            let params = {
                StandardModel: {
                    Code: this.state.code,
                    Name: this.state.name,
                    Year: this.state.year,
                    Desc: this.state.languageSP
                },
                StandardSubjectModels:[],
                StandardLanguageModels:[
                    {
                        LessonLangCode: "es",
                        Desc: this.state.languageSP,
                    },
                    {
                        LessonLangCode: "en",
                        Desc: this.state.languageEN,
                    }
                ]
            };

            for(let i=0; i < this.state.selectedSubjects.length;i++)
            {
                params.StandardSubjectModels.push({
                    SubjectCode: this.state.selectedSubjects[i],
                })
            }

            axios.post(apiStandardsCreate, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                    if(response.status === 201)
                    {
                        alert("Successfully created./Creado satisfactoriamente.");
                        this.setState({
                            redirect: true,
                        })
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                this.setState({loading: false});
            }).catch(error => {

                if(error.response.status === 400)
                {
                    if(typeof error.response.data !== 'undefined' && typeof error.response.data.Messages !== 'undefined' && error.response.data.Messages.length > 0)
                    {
                        let message = "";
                        for(let i=0;i<  error.response.data.Messages.length; i++)
                        {
                            message += error.response.data.Messages[i];
                        }
                        alert(message);
                    }
                }
                else {
                    alert(`Error: ${error}`);
                }
                this.setState({loading: false});
            });
        });
    }

    redirectToEdit(){
        this.setState({redirectToEdit: true})
    }

    render(){
        if(this.state.redirect){
            return <Redirect to='/view-standards' />
        }

        return(
            <Container fluid className="card-alt">
              <div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <h2><FormattedMessage id="std-standard"/></h2>
                            {this.state.loading ? <Button disabled variant="success">
                                <FormattedMessage id="std-create-standard"/></Button> :
                                <Button variant="success" onClick={this.onCreateCall}>
                                    <FormattedMessage id="std-create-standard"/>
                                </Button>}
                        </div>

                        <StandardDisplayView
                            isCreation={true}
                            language={this.props.language}
                            dataContent={this.state}
                            onChange={this.onChange}
                        />
                    </div>
            </Container>
        );
    }
}

export default CreateStandardPage;